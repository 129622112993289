import $ from "jquery"
import {
    classificationSelectVals,
    constructionSelectVals,
    illuminationSelectVals,
    mediaSelectVals,
    placementSelectVals,
    placeTypeSelectVals
} from "../supportFunctions/apiTypes";
import {createInventoryFromForm} from "../inventoryManagement/createInventory";
import {accounts, getDBreps, parent_accounts, setaccountSelectors} from "../supportFunctions/accounts";

export function createInventoryForm(){
    // console.log('here')
    $("#InventoryFormPlaceHolder").html('');
    $("#InventoryFormPlaceHolder").html('<div class="row">\n' +
        '    <div class="col-6">\n' +
        '        <form class="needs-validation" id="frameform">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Frame Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '\n' +
        '                    <th><label for="inputOperatorFrameID">Operator Frame ID</label><span class="requiredForm">*</span></th>\n' +
        '                    <td class="form-group"><input required aria-required="true" type="text" class="form-control" id="inputOperatorFrameID"\n' +
        '                               placeholder="Operator\'s Unique frame identifier -  no duplicates within a account">\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter an Operator ID\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputConstructionName">Construction Name</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="inputConstructionName"\n' +
        '                               placeholder="ID of the structure the frame is on">\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputConstructionDate">Construction Date</label></th>\n' +
        '                    <td><input type="date" class="form-control" id="inputConstructionDate"\n' +
        '                               placeholder="Date the frame was constructed/installed">\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputclassTypeDropDown">Classification Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputclassTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please select a classicification.\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="inputFrameHeightInches">Frame Height</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="inputheightFrameOffToggleDiv"><i title="Toggle Feet Input" id="inputheightFrameOffToggle"\n' +
        '                                                                class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="inputheightFrameOnToggleDiv" hidden><i title="Toggle Feet Input" id="inputheightFrameOnToggle"\n' +
        '                                                                      class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="inputheightFrameFtDiv">\n' +
        '                                <input type="number" class="form-control" id="inputFrameHeightFeet"\n' +
        '                                       placeholder="Height Feet">\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="inputheightFrameInDiv">\n' +
        '                                <input required aria-required="true" type="number" class="form-control"\n' +
        '                                       id="inputFrameHeightInches"\n' +
        '                                       placeholder="Height Inches">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter height in inches as a number\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="inputFrameWidthInches">Frame Width</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="inputwidthFrameOffToggleDiv"><i title="Toggle Feet Input" id="inputwidthFrameOffToggle"\n' +
        '                                                               class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="inputwidthFrameOnToggleDiv" hidden><i title="Toggle Feet Input" id="inputwidthFrameOnToggle"\n' +
        '                                                                     class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="inputwidthFrameFtDiv">\n' +
        '                                <input type="number" class="form-control" id="inputFrameWidthFeet"\n' +
        '                                       placeholder="Width Feet">\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="inputwidthFrameInDiv">\n' +
        '                                <input required aria-required="true" type="number" class="form-control"\n' +
        '                                       id="inputFrameWidthInches"\n' +
        '                                       placeholder="Width Inches">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter width in inches as a number.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFramePixelHeight">Pixel Height</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFramePixelHeight"\n' +
        '                               placeholder="For Digital Only Screen Height in Pixels"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFramePixelWidth">Pixel Width</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFramePixelWidth"\n' +
        '                               placeholder="For Digital Only Screen Width in Pixels"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameIllStart">Illumination Time</label><span class="requiredForm">*</span></th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="illStarDiv">\n' +
        '                                <input required aria-required="true" type="time" class="form-control"\n' +
        '                                       id="inputFrameIllStart"\n' +
        '                                       placeholder="Start Time"\n' +
        '                                       step="2" value="00:00:00">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter time as numbers to the second.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="illEndDiv">\n' +
        '                                <input required aria-required="true" type="time" class="form-control"\n' +
        '                                       id="inputFrameIllEnd" placeholder="End Time" step="2" value="23:59:59">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter time as numbers to the second.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputillTypeDropDown">Illumination Type </label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputillTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter an illumination type\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameMediaName">Frame Media Name</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="inputFrameMediaName"\n' +
        '                               placeholder="Media name used by operator"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputconstructionTypeDropDown">Construction Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputconstructionTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a construction type\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputmediaTypeDropDown">Media Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputmediaTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a Media Type\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameDigitalTF">Frame Digital</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputFrameDigitalTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameRotatingTF">Frame Rotating</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputFrameRotatingTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameFullMotionTF">Frame Full Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputFrameFullMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFramePartialMotionTF">Frame Partial Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputFramePartialMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameInteractiveTF">Frame Interactive</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputFrameInteractiveTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameAudioTF">Frame Audio</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputFrameAudioTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameEngagement">Frame Engagement</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFrameEngagement"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameDescription">Frame Description</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="inputFrameDescription"></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '        <form class="needs-validation">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Location Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameLatitude">Latitude</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><input required aria-required="true" type="number" class="form-control" id="inputFrameLatitude" min=24 max=50 step=any>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a latitude as a postive decimal number between 24 and 50\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFrameLongitude">Longitude</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><input required aria-required="true" type="number" class="form-control"\n' +
        '                               id="inputFrameLongitude" min=-125 max=-65 step=any>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a longtidue as a negative decimal number between -65 and -125\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="inputFrameOrientation">Orientation</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="inputorientationDirOffToggleDiv"><i title="Toggle Orientation Direction"\n' +
        '                                                                    id="orientationDirOffToggle"\n' +
        '                                                                    class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="inputorientationDirOnToggleDiv" hidden><i title="Toggle Orientation Direction"\n' +
        '                                                                          id="orientationDirOnToggle"\n' +
        '                                                                          class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div>\n' +
        '                            <div hidden id="inputorientationDegDiv">\n' +
        '                                <input type="number" class="form-control" id="inputFrameOrientation" min="0" max="359">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter orientation as a whole number between 0 and 359\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div id="inputorientationDirDiv">\n' +
        '                                <select style="width: 100%" required class="form-control select2" id="inputorientationDirDropDown">\n' +
        '                                    <option></option>\n' +
        '                                    <option>N</option>\n' +
        '                                    <option>NE</option>\n' +
        '                                    <option>E</option>\n' +
        '                                    <option>SE</option>\n' +
        '                                    <option>S</option>\n' +
        '                                    <option>SW</option>\n' +
        '                                    <option>W</option>\n' +
        '                                    <option>NW</option>\n' +
        '                                </select>\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please select an orientation from the list\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFramePrimaryArtery">Primary Artery</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="inputFramePrimaryArtery"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFramePrimaryRead">Primary Read</label></th>\n' +
        '                    <td>' +
        '                       <select  class="form-control select2" id="inputFramePrimaryRead">' +
        '                        <option></option>' +
        '                        <option>R</option>' +
        '                        <option>L</option>' +
        '                        <option>P</option>' +
        '                        <option>C</option>' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputPlaceTypeDropDown">Place Type</label></th>\n' +
        '                    <td><select style="width: 100%" class="form-control select2"\n' +
        '                                id="inputPlaceTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputPlacementTypeDropDown">Placement Type</label></th>\n' +
        '                    <td><select style="width: 100%" class="form-control select2"\n' +
        '                                id="inputPlacementTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '    </div>\n' +
        '    <div class="col-6">\n' +
        '        <form class="needs-validation">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Representation Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputParentAccountDropDown">Parent Account</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputParentAccountDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputAccountDropDown">Account</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputAccountDropDown"></select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputRepTypeDropDown">Representation Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="inputRepTypeDropDown"></select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please use this and the previous fields to select an operator and representation type. If the operator you need is not here, please create a new operator record seperately.\n' +
        '                        </div>\n' +
        '\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '        <form class="needs-validation">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Layout Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputLayoutSOV">Layout Share of Voice</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputLayoutSOV"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputLayoutRotatingTF">Layout Rotating</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputLayoutRotatingTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputLayoutFullMotionTF">Layout Full Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputLayoutFullMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputLayoutPartialMotionTF">Layout Partial Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputLayoutPartialMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputLayoutInteractiveTF">Layout Interactive</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputLayoutInteractiveTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputLayoutAudioTF">Layout Audio</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputLayoutAudioTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '        <form class="needs-validation">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Face Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="inputFaceHeightInches">Face Height</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="inputheightFaceOffToggleDiv"><i title="Toggle Feet Input" id="inputheightFaceOffToggle"\n' +
        '                                                                      class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="inputheightFaceOnToggleDiv" hidden><i title="Toggle Feet Input" id="inputheightFaceOnToggle"\n' +
        '                                                                            class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="inputheightFaceFtDiv">\n' +
        '                                <input type="number" class="form-control" id="inputFaceHeightFeet"\n' +
        '                                       placeholder="Height Feet">\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="inputheightFaceInDiv">\n' +
        '                                <input required aria-required="true" type="number" class="form-control"\n' +
        '                                       id="inputFaceHeightInches"\n' +
        '                                       placeholder="Height Inches">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter a height in inches. It should not exceed the height of the Frame.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="inputFaceWidthInches">Face Width</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="inputwidthFaceOffToggleDiv"><i title="Toggle Feet Input" id="inputwidthFaceOffToggle"\n' +
        '                                                                     class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="inputwidthFaceOnToggleDiv" hidden><i title="Toggle Feet Input" id="inputwidthFaceOnToggle"\n' +
        '                                                                           class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="inputwidthFaceFtDiv">\n' +
        '                                <input type="number" class="form-control" id="inputFaceWidthFeet"\n' +
        '                                       placeholder="Width Feet">\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="inputwidthFaceInDiv">\n' +
        '                                <input required aria-required="true" type="number" class="form-control"\n' +
        '                                       id="inputFaceWidthInches"\n' +
        '                                       placeholder="Width Inches">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter a width in inches. It should not exceed the width of the Frame.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFacePixelHeight">Face Pixel Height</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFacePixelHeight"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFacePixelWidth">Face Pixel Width</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFacePixelWidth"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFaceTopPixelXHeight">Face Top Pixel X Height</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFaceTopPixelXHeight"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputFaceTopPixelYWidth">Face Top Pixel Y Width</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputFaceTopPixelYWidth"></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '        <form class="needs-validation">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Spot Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputOperatorSpotID">Operator First(A) Spot ID</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><input required aria-required="true" type="text" class="form-control" id="inputOperatorSpotID"\n' +
        '                               placeholder="ID Used by the Operator">\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter an operator spot id\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '\n' +
        '                <tr>\n' +
        '                    <th><label for="inputPublisherUniqueID">Publisher Unique ID</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="inputPublisherUniqueID"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotCount">Number of Spots</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputSpotCount"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotLen">Spot Length</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="inputSpotLen"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotRotatingTF">Spot Rotating</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputSpotRotatingTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotFullMotionTF">Spot Full Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputSpotFullMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotPartialMotionTF">Spot Partial Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputSpotPartialMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotInteractiveTF">Spot Interactive</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputSpotInteractiveTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="inputSpotAudioTF">Spot Audio</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="inputSpotAudioTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '    </div>\n' +
        '</div>\n' +
        '<br>\n' +
        '<div class="row">\n' +
        '    <div class="col">\n' +
        '        <button id="createInventorySubmitBtn" type="button" class="btn-block btn-info">Create Record</button>\n' +
        '    </div>\n' +
        '</div>\n' +
        '<br>\n' +
        '<div class="row" id="createMessageDiv" hidden>\n' +
        '    <h5>Created Inventory</h5>\n' +
        '    <table class="table table-striped table-bordered table-sm">\n' +
        '        <tbody id="createdIDTable"></tbody>\n' +
        '    </table>\n' +
        '</div>');
    // $("#createInventoryFormPlaceHolder").load('/createHtml.html')
    $("#inputclassTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Classifciation Type",
        data: classificationSelectVals
    })
    $("#inputmediaTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Media Type",
        data: mediaSelectVals
    })
    $("#inputillTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Illumination Type",
        data: illuminationSelectVals
    })
    $("#inputconstructionTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Construction Type",
        data: constructionSelectVals
    })
    $("#inputorientationDirDropDown").select2({
        theme: 'bootstrap4',
        placeholder: 'Select A Facing Direction'
    })
    $("#inputPlaceTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Place Type",
        data: placeTypeSelectVals
    })
    $("#inputPlacementTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Placement Type",
        data: placementSelectVals
    })
    $("#inputParentAccountDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Parent Account",
        data: parent_accounts
    })
    $("#inputAccountDropDown").select2({
        theme: 'bootstrap4'
    })
    $("#inputRepTypeDropDown").select2({
        theme: 'bootstrap4'

    })

    $('#createInvAdvanced').on('click', function (){
        $(".advMode").attr('hidden',false)
        $("#createInvBasicLabel").removeClass('btn-secondary')
        $("#createInvBasicLabel").addClass('btn-outline-secondary')
    })
    $('#createInvBasic').on('click', function (){
        $(".advMode").attr('hidden',true)
        $("#createInvAdvancedLabel").removeClass('btn-secondary')
        $("#createInvAdvancedLabel").addClass('btn-outline-secondary')
    })
    $('#inputheightFrameOffToggle').on('click', function (){
        $('#inputheightFrameOffToggleDiv').attr('hidden', true)
        $("#inputheightFrameOnToggleDiv").attr('hidden', false)
        $("#inputheightFrameFtDiv").attr('hidden',true)
        $("#inputFrameHeightFeet").val('')
        $("#inputFrameHeightInches").val('')


    })
    $('#inputheightFrameOnToggle').on('click', function (){
        $("#inputheightFrameOnToggleDiv").attr('hidden', true)
        $('#inputheightFrameOffToggleDiv').attr('hidden', false)
        $("#inputheightFrameFtDiv").attr('hidden',false)
        $("#inputFrameHeightFeet").val('')
        $("#inputFrameHeightInches").val('')

    })
    $('#inputwidthFrameOffToggle').on('click', function (){
        $('#inputwidthFrameOffToggleDiv').attr('hidden', true)
        $("#inputwidthFrameOnToggleDiv").attr('hidden', false)
        $("#inputwidthFrameFtDiv").attr('hidden',true)
        $("#inputFrameWidthFeet").val('')
        $("#inputFrameWidthInches").val('')
    })
    $('#inputwidthFrameOnToggle').on('click', function (){
        $("#inputwidthFrameOnToggleDiv").attr('hidden', true)
        $('#inputwidthFrameOffToggleDiv').attr('hidden', false)
        $("#inputwidthFrameFtDiv").attr('hidden',false)
        $("#inputFrameWidthFeet").val('')
        $("#inputFrameWidthInches").val('')
    })
    $('#inputheightFaceOffToggle').on('click', function (){
        $('#inputheightFaceOffToggleDiv').attr('hidden', true)
        $("#inputheightFaceOnToggleDiv").attr('hidden', false)
        $("#inputheightFaceFtDiv").attr('hidden',true)
        $("#inputFaceHeightFeet").val('')
        $("#inputFaceHeightInches").val('')
    })
    $('#inputheightFaceOnToggle').on('click', function (){
        $("#inputheightFaceOnToggleDiv").attr('hidden', true)
        $('#inputheightFaceOffToggleDiv').attr('hidden', false)
        $("#inputheightFaceFtDiv").attr('hidden',false)
        $("#inputFaceHeightFeet").val('')
        $("#inputFaceHeightInches").val('')
    })
    $('#inputwidthFaceOffToggle').on('click', function (){
        $('#inputwidthFaceOffToggleDiv').attr('hidden', true)
        $("#inputwidthFaceOnToggleDiv").attr('hidden', false)
        $("#inputwidthFaceFtDiv").attr('hidden',true)
        $("#inputFaceWidthFeet").val('')
        $("#inputFaceWidthInches").val('')
    })
    $('#inputwidthFaceOnToggle').on('click', function (){
        $("#inputwidthFaceOnToggleDiv").attr('hidden', true)
        $('#inputwidthFaceOffToggleDiv').attr('hidden', false)
        $("#inputwidthFaceFtDiv").attr('hidden',false)
        $("#inputFaceWidthFeet").val('')
        $("#inputFaceWidthInches").val('')
    })
    $('#orientationDirOffToggle').on('click', function (){
        $("#inputFrameOrientation").attr('required',true)
        $("#inputorientationDirDropDown").attr('required',false)
        $('#inputorientationDirOffToggleDiv').attr('hidden', true)
        $("#inputorientationDirOnToggleDiv").attr('hidden', false)
        $("#inputorientationDirDiv").attr('hidden',true)
        $("#inputorientationDegDiv").attr('hidden',false)
    })
    $('#orientationDirOnToggle').on('click', function (){
        $("#inputFrameOrientation").attr('required',false)
        $("#inputorientationDirDropDown").attr('required',true)
        $("#inputorientationDirOnToggleDiv").attr('hidden', true)
        $('#inputorientationDirOffToggleDiv').attr('hidden', false)
        $("#inputorientationDirDiv").attr('hidden',false)
        $("#inputorientationDegDiv").attr('hidden',true)
    })
    $("#inputAccountDropDown").on('select2:select', function () {
        getDBreps($("#inputAccountDropDown").select2('data'),$("#inputRepTypeDropDown"))
    })
    $("#inputParentAccountDropDown").on('select2:select', function () {
        $("#formRepTypeDropDown").html('').select2({
            theme: 'bootstrap4',
            placeholder: "Select Rep Type",
            data: [{
                id: '',
                text: ''
            }]

        })
        setaccountSelectors(accounts, $("#inputAccountDropDown"), $("#inputParentAccountDropDown"))
    })
    $("#createInventorySubmitBtn").on('click',function (e){
        $("#createdIDTable").html('')
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.querySelectorAll('.needs-validation');
        let failvalidations = null
        failvalidations = []
        $.each(forms, function (index,value){
            if (!value.checkValidity()) {
                e.preventDefault();
                e.stopPropagation();
                failvalidations.push(value)
            }
            value.classList.add('was-validated')
            // console.log(failvalidations)
        })
        if (failvalidations.length === 0){
            createInventoryFromForm()
            $("#createMessageDiv").attr('hidden',false)
        }
        else{
            $("#createMessageDiv").attr('hidden',false)
            $("#createdIDTable").html('<tr><td>CREATE FAILED, Please Try Again</td></tr>')
        }
    })



}

