import $ from 'jquery'
import {info} from "../supportFunctions/consts";
import {convertIntoFt, degrees_to_orientation} from "../supportFunctions/mathFunctions";
import {getPhotos} from "../points/pointHandler";
import {parseLayoutData, spatialEditInventory} from "../points/pointActions";
import {checkInFrameWithoutEdit, checkOutFrame} from "../points/pointStatuses";
import {coordinateDisplay} from "../map_events/coordinateFunctions";
import {TabulatorFull as Tabulator} from "tabulator-tables";

require('bootstrap/js/dist/modal')

export function prepFrameInfoDisplay(data){
    // console.log('prepping info display')
    info.html(
        '<div id="frame_info"></div>'
    )
    frameInfoDisplay(data)
}


export async function frameInfoDisplay(data) {
    coordinateDisplay([data['location']['longitude'],data['location']['latitude']])
    let tableclassframeid = "table-info"
    let tableclassstatus = "table"
    let redvalues = [80,90]
    if(redvalues.indexOf(data['status_type']['id']) !== -1){
        tableclassframeid = 'table-danger'
        tableclassstatus = 'table-danger'
    }
    let frameHtFeet = convertIntoFt(data['max_height']);
    let frameWtFeet = convertIntoFt(data['max_width']);
    let layoutjson = await parseLayoutData(data)
    let placetypename
    let placementtypename
    if (data['location']['place_type']){
        placetypename = data['location']['place_type']['name']
    }
    else if (data['location']['places'][0]) {
        placetypename = data['location']['places'][0]['place_type']['name']
    }
    else {
        placetypename = "No Place Type"
    }
    if (data['placement_type']){
        placementtypename = data['placement_type']['name']
    }
    // else if (data['location']['places'][0]['placement_type']) {
    //     placementtypename = data['location']['places'][0]['placement_type']['name']
    // }
    // else if (data['placement_type']) {
    //     placementtypename = data['placement_type']['name']
    // }
    else {
        placementtypename = "No Placement Type"
    }
    try {
        $("#frame_info").html(
            '<table class="table table-striped table-bordered table-sm">'+
            '<tbody>' +
            '   <tr class="'+tableclassframeid+'">' +
            '      <th scope="row">Frame ID</th>' +
            '      <td>'+'<span id="currentGeoID">' + data.id + '</span>'+'&nbsp<button class="clipboard" id="frameidcopy" data-clipboard-text="' + data.id + '"><i class="far fa-clipboard"></i></button>&nbsp<button id="editorButton"><i class="far fa-edit"></i></button></td>' +
            '   </tr>' +
            '   <tr class="'+tableclassstatus+'">'+
            '      <th scope="row">Status</th>' +
            '      <td>'+ data["status_type"]["name"]  +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Classification</th>' +
            '      <td>'+ data['classification_type']['name']+'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Media Name / Media Type</th>' +
            '      <td>'+ data.media_name +' / '+ data['media_type']['name']+'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Operator / Account</th>' +
            '      <td>'+ data['representations'][0]['account']['parent_account_name']+ ' / ' + data['representations'][0]['account']['name']+'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Operator ID</th>' +
            '      <td>'+'<span id="currentGeoID">' + data.plant_frame_id + '</span>'+'&nbsp<button class="clipboard" id="plantframeidcopy" data-clipboard-text="' + data.plant_frame_id + '"><i class="far fa-clipboard"></i></td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Construction Name</th>' +
            '      <td>'+ data['construction']['name'] +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Construction Type</th>' +
            '      <td>'+ data['construction']['construction_type']['name'] +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Rotating</th>' +
            '      <td>'+ data.rotating +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Digital</th>' +
            '      <td>'+ data.digital +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Content</th>' +
            '      <td><button type="button" id="frameSpotbtn" class="btn-primary" ' +
            '>Frame Content Info</button></td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Facing </th>' +
            '      <td>'+ degrees_to_orientation(parseInt(data["location"]['orientation'])) +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Facing (degrees)</th>' +
            '      <td>'+ data["location"]['orientation'] +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '       <th scope="row">Frame Height</th>' +
            '       <td>'+ frameHtFeet +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '       <th scope="row">Frame Width </th>' +
            '       <td>'+ frameWtFeet +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Illumination Start / End</th>' +
            '      <td>'+ data["illumination_start_time"]+' / '+data["illumination_end_time"]+'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Illumination Type</th>' +
            '      <td>'+ data['illumination_type']['name']+'</td>' +
            '   </tr>' +
            '   <tr>'+
            '       <th scope="row">Primary Artery </th>' +
            '       <td>'+ data["location"]["primary_artery"] +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '       <th scope="row">Primary Read </th>' +
            '       <td>'+ data["location"]["primary_read"] +'</td>' +
            '   </tr>' +
            '   <tr>'+
            '      <th scope="row">Place / Placement Type</th>' +
            '      <td>'+ placetypename+' / '+ placementtypename+'</td>' +
            '   </tr>' +
            '   <tr>'+
            '       <th scope="row">Photo </th>' +
            '       <td id="pic"><a href="https://images.geopath.org/'+data.id+'.jpeg " target=" + _blank + "><img src="https://images.geopath.org/'+data.id+'.jpeg?width=110&height=60"></td>' +
            '   </tr>' +
            '</tbody>'
        )
    }
    catch (error){
        $("#frame_info").html(
            '<h3>There was an error</h3>' +
            '<p>This occured when reading the data returned from the API</p>' +
            '<p>It is likely that some data is missing from the FRAME record in the database</p>' +
            '<p>Please see below for more information</p>' +
            '<hr>'+
            error +
            '<hr>'

        )
    }


    $("#editorButton").on('click',async function () {
        let checkedcheck = await checkOutFrame();
        if (checkedcheck === true){
            spatialEditInventory()
        }
        else {
            $("#mapUtilsHeader").html(
                '<h4>Error. the frame is already checked out</h4><br>' +
                '<button class="btn btn-sm btn-danger" id="forceCheckIn">Force Check In</button>'
            )
            $("#forceCheckIn").on('click', function () {
                checkInFrameWithoutEdit()
            })
        }
    });
    $("#frameModal").on('shown.bs.modal', function (e){
        // console.log(layoutjson)
        createLayoutTabulator(layoutjson)
    })
    $("#frameSpotbtn").on('click', async function (){
        $("#frameModal").modal('show');
    })
    getPhotos(data['photos'])
}

export function createLayoutTabulator(layoutjson) {
    let layoutTable
    let subTable
    let spotsubTable
    layoutTable = new Tabulator("#frameSpotTable", {
        data:layoutjson,
        layout:"fitColumns",
        height: "75%",
        columns:[
            {"title": "Layout ID", "field":"Layout_ID"},
            {"title": "Layout SOV", "field":"Layout_SOV"}
        ],
        rowFormatter:function(row){
            //create and style holder elements
            var faceholderEl = document.createElement("div");
            var facetableEl = document.createElement("div");

            faceholderEl.style.boxSizing = "border-box";
            faceholderEl.style.padding = "10px 30px 10px 10px";
            faceholderEl.style.borderTop = "1px solid #333";
            faceholderEl.style.borderBotom = "1px solid #333";
            faceholderEl.style.background = "#ddd";

            facetableEl.style.border = "1px solid #333";

            faceholderEl.appendChild(facetableEl);

            row.getElement().appendChild(faceholderEl);
            subTable = new Tabulator(facetableEl, {
                layout:"fitColumns",
                height: "75%",
                data:row.getData().faces,
                columns:[
                    {title:"Face ID", field:"Face_ID"},
                    {title:"Face Publisher ID", field:"Publisher_Unique_Face_ID"},
                    {title:"Face Height", field:"Face_Height_Feet"},
                    {title:"Face Width", field:"Face_Width_Feet"},
                    {title:"Spots in Rotation", field:"Spots_in_Rotation"},
                    {title:"Avg Spot Length", field:"Avg_Spot_Length"},
                ],
                rowFormatter:function(row){
                    //create and style holder elements
                    var spotholderEl = document.createElement("div");
                    var spottableEl = document.createElement("div");

                    spotholderEl.style.boxSizing = "border-box";
                    spotholderEl.style.padding = "10px 30px 10px 10px";
                    spotholderEl.style.borderTop = "1px solid #333";
                    spotholderEl.style.borderBotom = "1px solid #333";
                    spotholderEl.style.background = "#ddd";

                    spottableEl.style.border = "1px solid #333";

                    spotholderEl.appendChild(spottableEl);

                    row.getElement().appendChild(spotholderEl);
                    spotsubTable = new Tabulator(spottableEl, {
                        layout:"fitColumns",
                        height: "75%",
                        data:row.getData().spots,
                        columns:[
                            {title:"Spot ID", field:"Spot_ID",},
                            {title:"Operator Spot ID", field:"Operator_Spot_ID"},
                            {title:"Publisher Unique ID", field:"Publisher_Unique_ID"},
                            {title:"Spots Length", field:"Spot_Length"},
                            {title:"Spot SOV", field:"Spot_SOV"},
                        ]
                    })
                }
            })
        },
    });
}