import $ from "jquery";
import {
    createAssignmentTable,
    createLegacyAssignmentTable,
    createPlaceBasedAssignmentTable,
} from "./assignmentInfoDisplay";
import {GeoJSON} from 'ol/format';
import {assignmentsource, assignmentsource2021, assignmentsource2023, placebasedassignmentsource} from "../layers/activeLayers";
import {getLineShapes} from "../lines/lineHandler";
import {round} from "../supportFunctions/mathFunctions";
import {getExtentofAssignmentsAndPoints, getExtentofPlaceAssignmentsAndPoints} from "../supportFunctions/mapFunctions";
import {hideSubMapLoading, showSubMapLoading} from "../supportFunctions/consts";
import {getPlaceShapes} from "../polygons/polyHandler";
import {getPanelJSON} from "../points/pointFunctions";


//set variable array to fill with the assignments that will get used later when creating assignments to check for duplicates
//raw exisitng assingments required for re-calculating assignments
export let exisitingAssignments = []
export let rawExisitingAssignments = []
export let shapePromises = []
export let cstationShapePromises = []

//get the assignments for the board from the geopath api
export async function apiGetAssignments() {
    let formData = {
        "geopath_id": $('#currentpanelid').text()
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getAssignments',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": formData
    };
    showSubMapLoading()
    $.ajax(settings).done(function (response) {
        parseAssignmentResponse(response)
    })
}

//take api response and parse out required info
export async function parseAssignmentResponse(response) {
    // console.log(response)
    exisitingAssignments.length = 0
    let cstationAssignmentList = [];
    let hereAssignmentList = [];
    let hereLoopPromises = [];
    let placeBasedAssignmentList =[]
    let type3TF = false;
    if (response['assignments'].length > 0) {
        $.each(response, function (index, values) {
            //create promises to complete
            hereLoopPromises.push(new Promise(function (resolve) {
                $.each(values, function (index, value) {
                    //do this for type 2/3/9 assignments
                    if ([2, 3, 9,10].includes(value["assignment_type"]["id"])) {
                        // console.log(value)
                        let segval = String(value["segment_id"]);
                        let segdir = segval.split("_", 2);
                        let networkyear
                        if (value['network_type']['id'] === 2){
                            networkyear = 2016
                        }
                        else if (value['network_type']['id'] === 6){
                            networkyear = 2021
                        }
                        else if (value['network_type']['id'] === 9){
                            networkyear = 2023
                        }
                        //create detailed val for here assignment display list
                        let newVal = {
                            assignment_type_id: value["assignment_type"]["id"],
                            assignment_id: value["id"],
                            segment_id: value['segment_id'],
                            mode: value["mode"],
                            center_read: value['center'],
                            dfta: value['distance_from_audience'],
                            placement_adjustment_factor: value['placement_adjustment_factor'],
                            perpendicular: value['perpendicular'],
                            right_read: value['right_hand'],
                            assignment_date: value['assignment_date'],
                            network_year: networkyear
                        };
                        // create more basic val for use in assignment duplicate checker during assignment creation
                        let currentAssignVal = {
                            frame_id: $('#currentGeoID').text(),
                            segment_id: segval,
                            mode: value["mode"],
                            network_year: networkyear
                        };
                        exisitingAssignments.push(currentAssignVal)
                        hereAssignmentList.push(newVal);
                        rawExisitingAssignments.push(value);
                        resolve()
                    }
                    //do this for type 1 assignments
                    else if ([1].includes(value["assignment_type"]["id"])) {
                        let newVal = {
                            assignment_type_id: value["assignment_type"]["id"],
                            assignment_id: value["id"],
                            segment_id: value["segment_id"],
                            center: value["center"]
                        };
                        cstationAssignmentList.push(newVal)
                        resolve()
                    }
                    else if([4,5].includes(value["assignment_type"]["id"])){
                        let newVal = {
                            assignment_type_id: value["assignment_type"]["id"],
                            assignment_id: value["id"],
                            place_id: value["place_id"]
                        };
                        placeBasedAssignmentList.push(newVal)
                        resolve()
                    }
                    else {

                        resolve()
                    }
                })
            }))
        });
    }
    //finish all promises and go get shapes
    await Promise.all(hereLoopPromises).then(function () {
        let paneljson = getPanelJSON()
        if (hereAssignmentList.length === 0 && placeBasedAssignmentList.length === 0){
            $("#assignmentHeaderText").html('<h5>No Active Assignments</h5>')
            if (paneljson['classification_type']['id'] !== 4){
                $("#createAssignment").prop('hidden', false)
            }
            hideSubMapLoading()
        }
        else if ((hereAssignmentList.length === 0) ||(placeBasedAssignmentList.length !== 0)) {
            // $("#assignmentHeaderText").html('<h5>No Active Assignments</h5>')
            getPlaceBasedAssignmentShapes(placeBasedAssignmentList)
            hideSubMapLoading()
        }
        else {
            getAssignmentShapes(hereAssignmentList,cstationAssignmentList)
        }
    })
}

//get the geojson shapes of the assigned segments to add to the map
export async function getAssignmentShapes(hereAssignmentList,cstationAssignmentList) {
    // console.log(hereAssignmentList)
    let flatAssignmentProps = []
    let flatCstationProps = []
    shapePromises.length = 0
    cstationShapePromises.length = 0
    $.each(hereAssignmentList, function (index, value) {
        shapePromises.push(new Promise(function (resolve) {
            // console.log(value)
            const segmentid = value['segment_id'];
            const network_year = value['network_year']
            //prepapre geoserver request
            let settings = getLineShapes(segmentid,network_year)
            //send geoserver request
            resolve($.ajax(settings).done(function (response) {
                    //process json response
                    // console.log(response)
                    let geojsonfeatures = new GeoJSON().readFeatures(response[0]['feature']);
                    // console.log(geojsonfeatures)
                    let segprops = response[0]['feature']['features'][0]['properties']
                    flatAssignmentProps.push({
                        assignment_type_id: value["assignment_type_id"],
                        assignment_id: value["assignment_id"],
                        network_year: value['network_year'],
                        segment_id: value['segment_id'],
                        mode: value["mode"],
                        center_read: value['center_read'],
                        dfta: value['dfta'],
                        placement_adjustment_factor: value['placement_adjustment_factor'],
                        perpendicular: value['perpendicular'],
                        right_read: value['right_read'],
                        assignment_date: value['assignment_date'],
                        seg_dir: segprops['direction'],
                        seg_head: segprops['heading'],
                        veh_circ: round(segprops['veh_aadt'] * segprops['wav_occ'], 0),
                        veh_aadt: round(segprops['veh_aadt'], 0),
                        ped_circ: round(segprops['ped_aadt'], 0),
                        func_class: segprops['func_class'],
                        st_name: segprops['st_name'],
                        wav_occ: segprops['wav_occ'],
                        wav_speed: segprops['wav_speed'],
                        wav_con: segprops['wav_con']
                    })
                    //add geojson features to the assingment layer
                    if (network_year === 2016){
                        // console.log(geojsonfeatures)
                        assignmentsource.addFeatures(geojsonfeatures);
                    }
                    if (network_year === 2021){
                        // console.log(geojsonfeatures)
                        assignmentsource2021.addFeatures(geojsonfeatures)
                    }
                    if (network_year === 2023){
                        // console.log(geojsonfeatures)
                        assignmentsource2023.addFeatures(geojsonfeatures)
                    }

                })
            )
        }))
    })
    //realign map and create the table
    await Promise.all(shapePromises).then(function () {
        getExtentofAssignmentsAndPoints(assignmentsource)
        createAssignmentTable(flatAssignmentProps)
        hideSubMapLoading()
    })
    if(cstationAssignmentList.length > 0) {
        $.each(cstationAssignmentList, function (index, value) {
            cstationShapePromises.push(new Promise(function (resolve) {
                const cstationid = parseInt(value['segment_id']);
                let settings = {
                    "url": process.env.appurl + "/api/getCstationShapes/",
                    "method": "get",
                    "content-type": "application/json",
                    "headers": {
                        "Authorization": "Bearer " + localStorage.getItem('access_token')
                    },
                    "data": {
                        "segment": cstationid
                    }
                }
                resolve($.ajax(settings).done(function (response) {
                        if (response.length > 0) {
                            flatCstationProps.push({
                                "assignment_id": value['assignment_id'],
                                "segment_id": value['segment_id'],
                                "st_name": response[0]['feature']['features'][0]['properties']['street_name'],
                                "direction": response[0]['feature']['features'][0]['properties']['direction'],
                                "center": value['center'],
                                "func_class": response[0]['feature']['features'][0]['properties']['fcc'],
                                "veh_aadt": response[0]['feature']['features'][0]['properties']['veh_cnt'],
                                "ped_aadt": response[0]['feature']['features'][0]['properties']['ped_cnt'],
                            })
                        }
                    })
                )
            }))
        })
        await Promise.all(cstationShapePromises).then(function () {
            createLegacyAssignmentTable(flatCstationProps)
        })
    }
}


export async function getPlaceBasedAssignmentShapes(placeBasedAssignmentList){
    let flatAssignmentProps = []
    shapePromises.length = 0
    cstationShapePromises.length = 0
    $.each(placeBasedAssignmentList, function (index,value) {
        shapePromises.push(new Promise(function (resolve) {
            // console.log(value)
            let place_id = value['place_id'];
            // console.log(segmentid)
            //prepapre geoserver request
            let settings = getPlaceShapes(place_id)
            //send geoserver request
            resolve($.ajax(settings).done(function (response) {
                //process json response
                // console.log(response)
                // console.log(response['data']['polygons'])
                let geojason = {
                    "type": "FeatureCollection",
                    "features": [{
                        "type":"Feature",
                        "properties":{
                            "place_id": response['data']['id'],
                        },
                        "geometry":response['data']['polygons']
                        }
                    ]
                }
                let geojsonfeatures = new GeoJSON().readFeatures(geojason,{
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                flatAssignmentProps.push({
                    assignment_type_id: value["assignment_type_id"],
                    assignment_id: value["assignment_id"],
                    place_id: value['place_id'],
                    place_name: response['data']['place_name']
                })
                //add geojson features to the assingment layer
                placebasedassignmentsource.addFeatures(geojsonfeatures);
                // console.log(placebasedassignmentsource)
            }))
        }))
    })
    await Promise.all(shapePromises).then(function () {
        getExtentofPlaceAssignmentsAndPoints()
        createPlaceBasedAssignmentTable(flatAssignmentProps)
        hideSubMapLoading()
    })
}

