import {clearAssignmentLayer} from "../layers/activeLayers";
import {getLineShapes} from "../lines/lineHandler";
import $ from "jquery";
import {st2021_vt_ly, st2023_vt_ly, st_vt_ly} from "../layers/vectorTileLayers";
import {clearOtherInfoDiv, searchfield} from "../supportFunctions/consts";
import {selectedSegmentStyle} from "../map_styles/lineStyle";
import {indLineInfo} from "../infoDisplays/individualLineInfo";
import {map} from "../map";
import {boundingExtent} from 'ol/extent'
import * as net from "net";


export function segmentSearch(val,network_year){
    console.log(network_year)
    clearAssignmentLayer();
    clearOtherInfoDiv()
    // create wfs call to get line shapes
    let settings = getLineShapes(val,network_year)
// then post the request and add the received features to a layer
    $.ajax(settings).done(function (response) {
        console.log(response)
        if(response.length > 0){
            //process json response
            let props = response[0]['feature']['features'][0];
            //re center map and provide data for basic information display
            let a = props['geometry']['coordinates'][0][0]
            let b = props['geometry']['coordinates'][0][1]
            let c = props['geometry']['coordinates'][1][0]
            let d = props['geometry']['coordinates'][1][1]
            // console.log(a,b,c,d)
            let extent = boundingExtent([[a,b],[c,d]])
            map.getView().fit(extent)
            $("#otherinfo").html('<h4> Individual Segment Info</h4>')
            indLineInfo(props['properties'],null,null,null,null,null)
        }
        else {
            alert('Segment Not Found, please try a different network search')

        }

    });
    //set style
    if(network_year === 2016){
        st_vt_ly.setStyle(function () {
            selectedSegmentStyle(val,network_year)
        });
    }
    else if (network_year === 2021){
        st2021_vt_ly.setStyle(function () {
            selectedSegmentStyle(val,network_year)
        });
    }
    else if (network_year === 2023){
        st2023_vt_ly.setStyle(function () {
            selectedSegmentStyle(val,network_year)
        });
    }


    searchfield.val('')
}