import $ from 'jquery'
import {
    createSingleSpotHourlyMeasuresTable,
    createSingleSpotMeasuresTable,
    createSummaryMeasuresTable
} from "./insightsInfoDisplays";
import Papa from 'papaparse'

export function getSingleSpotMeasures (){
    $('#insightsTableLoading').attr('hidden', false)
    let idlist =[]
    let papaconfig = {
        delimiter: "",
        newline: "\\r\\n",
        quoteChar:"'",
        skipEmptyLines:true
    }
    let papaList = Papa.parse($("#spot_id_list_multi").val(),papaconfig)["data"]
    $.each(papaList,function (index,value){
        idlist.push(value[0])
    })
    let targetseg
    let targetGeog
    if ($("#multi_0").is(":checked")){
        targetseg = 2032
    }
    else if ($("#multi_5").is(":checked")){
        targetseg = 8923
    }
    else if ($("#multi_18").is(":checked")){
        targetseg = 7166
    }
    else if ($("#multi_21").is(":checked")){
        targetseg = 8228
    }
    else{
        targetseg = $("#segment_id_multi").val()
    }
    if ($("#multi_local_dma").is(":checked")){
        targetGeog = ["local_dma"]
    }
    else{
        targetGeog =[]
        let geogPapaList = Papa.parse($("#target_geography_multi").val(),papaconfig)["data"]
        $.each(geogPapaList,function (index,value){
            targetGeog.push(value[0])
        })

    }
    // console.log(Papa.parse($("#spot_id_list_multi").val(),papaconfig))
    let body = {
        "id_list":idlist,
        "target_segment" : targetseg,
        "target_geography_list" : targetGeog,
        "hourly_measures": $("#hourly_single").is(":checked"),
        "period_days": parseInt($("#period_select_multi").val()),
        "status_type_name_list": ["*"],
        "fieldset":["frame_id","representations","media_name","media_type.name","status_type.name",
            "spot_references.spot_id","spot_references.measures","spot_references.measures_time_calculated",
            "spot_references.total_msg_impressions"],
        "base_segment":2032,
        "measures_required":true,
        "id_type":"spot_id",
    }
    let bodyString = JSON.stringify(body)

    let settings = {
        "url": process.env.appurl + "/api/getmeasuresbySpot",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data":{"search":bodyString}
    };
    // console.log(settings)
    $.ajax(settings).done(function (response){
        // console.log(response)
        let weeklydata = []
        let hourlydata =[]
        $.each(response['data']['inventory_summary']['inventory_items'],function (index,value){
            // console.log(value)
            weeklydata.push({
                    "frame_id":value["frame_id"],
                    "spot_id": value['spot_references'][0]["spot_id"],
                    "media_name":value['media_name'],
                    "media_type":value["media_type"]["name"],
                    "parent_account": value['representations'][0]['account']["parent_account_name"],
                    "account": value['representations'][0]['account']["name"],
                    "base_segment": value['spot_references'][0]['measures']['base_segment'],
                    "eff_freq_avg": value['spot_references'][0]['measures']['eff_freq_avg'],
                    "eff_freq_min": value['spot_references'][0]['measures']['eff_freq_min'],
                    "eff_reach_net": value['spot_references'][0]['measures']['eff_reach_net'],
                    "eff_reach_pct": value['spot_references'][0]['measures']['eff_reach_pct'],
                    "freq_avg": value['spot_references'][0]['measures']['freq_avg'],
                    "imp": value['spot_references'][0]['measures']['imp'],
                    "imp_inmkt": value['spot_references'][0]['measures']['imp_inmkt'],
                    "imp_target": value['spot_references'][0]['measures']['imp_target'],
                    "imp_target_inmkt": value['spot_references'][0]['measures']['imp_target_inmkt'],
                    "index_comp_target": value['spot_references'][0]['measures']['index_comp_target'],
                    "market": value['spot_references'][0]['measures']['market'],
                    "measures_release": value['spot_references'][0]['measures']['measures_release'],
                    "pct_comp_imp_target": value['spot_references'][0]['measures']['pct_comp_imp_target'],
                    "pct_comp_imp_target_inmkt": value['spot_references'][0]['measures']['pct_comp_imp_target_inmkt'],
                    "pct_comp_pop_target_inmkt": value['spot_references'][0]['measures']['pct_comp_pop_target_inmkt'],
                    "pct_imp_inmkt": value['spot_references'][0]['measures']['pct_imp_inmkt'],
                    "pct_imp_target_inmkt": value['spot_references'][0]['measures']['pct_imp_target_inmkt'],
                    "period_days": value['spot_references'][0]['measures']['period_days'],
                    "pop_inmkt": value['spot_references'][0]['measures']['pop_inmkt'],
                    "pop_target_inmkt": value['spot_references'][0]['measures']['pop_target_inmkt'],
                    "reach_net": value['spot_references'][0]['measures']['reach_net'],
                    "reach_pct": value['spot_references'][0]['measures']['reach_pct'],
                    "target_geo": value['spot_references'][0]['measures']['target_geo'],
                    "target_segment": value['spot_references'][0]['measures']['target_segment'],
                    "measures_time_calculated": value['spot_references'][0]["measures_time_calculated"]
                })
            if($("#hourly_single").is(":checked") === true){
                let spot_id = value['spot_references'][0]["spot_id"]
                let monvals = value['spot_references'][0]['total_msg_impressions']['mon']
                let tuesvals = value['spot_references'][0]['total_msg_impressions']['tue']
                let wedvals = value['spot_references'][0]['total_msg_impressions']['wed']
                let thursvals = value['spot_references'][0]['total_msg_impressions']['thu']
                let frivals = value['spot_references'][0]['total_msg_impressions']['fri']
                let satvals = value['spot_references'][0]['total_msg_impressions']['sat']
                let sunvals = value['spot_references'][0]['total_msg_impressions']['sun']
                Object.assign(monvals,{spot_id:spot_id,day:"Monday"})
                Object.assign(tuesvals,{spot_id:spot_id,day:"Tuesday"})
                Object.assign(wedvals,{spot_id:spot_id,day:"Wednesday"})
                Object.assign(thursvals,{spot_id:spot_id,day:"Thursday"})
                Object.assign(frivals,{spot_id:spot_id,day:"Friday"})
                Object.assign(satvals,{spot_id:spot_id,day:"Saturday"})
                Object.assign(sunvals,{spot_id:spot_id,day:"Sunday"})
                hourlydata.push(monvals,tuesvals,wedvals,thursvals,frivals,satvals,sunvals)
            }
        })
        createSingleSpotMeasuresTable(weeklydata)
        if($("#hourly_single").is(":checked") === true){
            createSingleSpotHourlyMeasuresTable(hourlydata)
        }
        $('#insightsTableLoading').attr('hidden', true)
    })
}

export function getSummaryMeasures (){
    $('#insightsTableLoading').attr('hidden', false)
    let idlist =[]
    let papaconfig = {
        delimiter: "",
        newline: "\\r\\n",
        quoteChar:"'",
        skipEmptyLines:true
    }
    let papaList = Papa.parse($("#spot_id_list_sum").val(),papaconfig)["data"]
    $.each(papaList,function (index,value){
        idlist.push(value[0])
    })
    let targetseg
    let targetGeog
    if ($("#sum_0").is(":checked")){
        targetseg = 2032
    }
    else if ($("#sum_5").is(":checked")){
        targetseg = 8923
    }
    else if ($("#sum_18").is(":checked")){
        targetseg = 7166
    }
    else if ($("#sum_21").is(":checked")){
        targetseg = 8228
    }
    else{
        targetseg = $("#segment_id_sum").val()
    }
    if ($("#sum_local_dma").is(":checked")){
        targetGeog = ["local_dma"]
    }
    else{
        targetGeog =[]
        let geogPapaList = Papa.parse($("#target_geography_sum").val(),papaconfig)["data"]
        $.each(geogPapaList,function (index,value){
            targetGeog.push(value[0])
        })

    }
    // console.log(Papa.parse($("#spot_id_list_sum").val(),papaconfig))
    let body = {
        "id_list":idlist,
        "target_segment" : targetseg,
        "target_geography_list" : targetGeog,
        "period_days": parseInt($("#period_select_sum").val()),
        "status_type_name_list": ["*"],
        "base_segment":2032,
        "measures_required":true,
        "id_type":"spot_id",
    }
    let bodyString = JSON.stringify(body)

    let settings = {
        "url": process.env.appurl + "/api/getSummaryMeasures",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data":{"search":bodyString}
    };
    // console.log(settings)
    $.ajax(settings).done(function (response){
        // console.log(response)
        createSummaryMeasuresTable(response['data']['summaries'])
        $('#insightsTableLoading').attr('hidden', true)
    })
}

export function tableDownload (table,name){
    table.download("csv",name +".csv")
}