import $ from 'jquery'
import {TabulatorFull as Tabulator} from "tabulator-tables";
import {setNewMapCenter, transform4326to3857} from "../supportFunctions/mapFunctions";
import {selectedFilteredInventory} from "../map_styles/pointStyles";
import {apiGetFrame} from "../points/pointHandler";
import {
    clearAssignmentHeaderText,
    clearInfoDiv,
    clearMapUtilsBody,
    clearMapUtilsHeader,
    clearNextButton,
    clearOtherInfoDiv,
    clearPrevButton,
    clearSubMapRow,
    hideAssignManagementButtons,
    hideAssignmentsButton,
    hideAssignmentTable,
    showFilteredInventoryTable
} from "../supportFunctions/consts";
import {framemediaParamBuilder, statusParamBuilder} from "./tableParamBuilders";
import {clearAssignmentLayer, clearCoordinateSearchLayer} from "../layers/activeLayers";
import {streetDefaultStyle} from "../map_styles/lineStyle";


let filteredInventoryTable

export function customFilter(data,filterParams){
    return data.frame_id >= filterParams.fromFrameID && data.frame_id <= filterParams.toFrameId
}

export function filterbyFrameID() {
    let filterParams = {"fromFrameID":$("#frameIdFrom").val(),"toFrameId":$("#frameIdTo").val()}
    filteredInventoryTable.setFilter(customFilter,filterParams)
}


export function displayFilteredInventoryTable(data){
    $("#frameIDFilter").prop('hidden',false)
    // $("#filteredInventoryHeaderHolder").prop('hidden',false)
    showFilteredInventoryTable()
    // $("#filteredInventoryHeader").html('<h6>'+ data.length + ' Total Inventory</h6>')
    filteredInventoryTable = new Tabulator ("#filteredInventoryTable",{
        selectable: 1,
        data: data,
        layout: "fitDataFill",
        height: "25vh",
        initialSort:[{column:"frame_id",dir:"asc"}],
        columns:[
            {
                title: "Frame ID",
                field: "frame_id",
                headerFilter:"input",
                bottomCalc:'count',
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Total: ",
                    precision:0
                }
            },
            {
                title: "Op. ID",
                field: "plant_frame_id",
                headerFilter:"input"
            },
            {
                title: "Media",
                field: "frame_media_name",
                headerFilter: "list",
                headerFilterParams: framemediaParamBuilder,
                headerFilterFunc: "="
            },
            {
                title: "Status",
                field: "status_type_name",
                headerFilter: "list",
                headerFilterParams: statusParamBuilder,
                headerFilterFunc: "="
            },
            {
                title: "Lon",
                field: "longitude",
                visible: false
            },
            {
                title: "Lat",
                field: "latitude",
                visible:false
            }
        ],
        // dataLoaded: function () {
        //     this.getColumn("frame_media_name").reloadHeaderFilter();
        //     this.getColumn("status_type_name").reloadHeaderFilter();
        // }
    })
    filteredInventoryTable.on('rowClick',function (e, row,) {
        // let selectedCoord = [row.getData().longitude,row.getData().latitude];
        clearAssignmentLayer()
        clearCoordinateSearchLayer()
        clearMapUtilsBody()
        clearMapUtilsHeader()
        clearOtherInfoDiv()
        clearInfoDiv()
        clearNextButton()
        clearPrevButton()
        streetDefaultStyle()
        clearSubMapRow()
        hideAssignmentsButton()
        hideAssignmentTable()
        clearAssignmentHeaderText()
        hideAssignManagementButtons()
        setNewMapCenter(transform4326to3857(row.getData().longitude,row.getData().latitude))
        selectedFilteredInventory(row.getData().frame_id)
        apiGetFrame(row.getData().frame_id)
    })
    filteredInventoryTable.on('tableBuilt',function (){
        filteredInventoryTable.getColumn("frame_media_name").reloadHeaderFilter();
        filteredInventoryTable.getColumn("status_type_name").reloadHeaderFilter();
    })

}

