import {cstation_vt_ly, st2021_vt_ly, st2023_vt_ly, st_vt_ly} from "../layers/vectorTileLayers";
import {Stroke, Style} from 'ol/style.js';


export function selectedSegmentStyle(idProp,network_year){
    console.log(network_year)
    let layer
    let layer_name
    if (network_year === 2016){
        layer = st_vt_ly
    }
    else if (network_year === 2021){
        layer = st2021_vt_ly
    }
    else if (network_year === 2023){
        layer = st2023_vt_ly
    }
    layer.setStyle(function (feature) {
        const properties = feature.getProperties();
        if (properties.segment_reg === idProp) {
            return new Style({
                stroke: new Stroke({
                    color: [0, 131, 154],
                    width: 12
                })
            })
        }
        else if (properties.segment === idProp) {
            return new Style({
                stroke: new Stroke({
                    color: [0, 131, 154],
                    width: 12
                })
            })
        }
        else {
            return new Style({
                stroke: new Stroke({
                    color: [1, 1, 1, 0.6],
                    width: 8
                })
            })
        }
    });
}

export function selectedCstationStyle(idProp){
    cstation_vt_ly.setStyle(function (feature) {
        const properties = feature.getProperties();
        if (properties.cstationid === idProp) {
            return new Style({
                stroke: new Stroke({
                    color: [0, 131, 154],
                    width: 12
                })
            })
        } else {
            return new Style({
                stroke: new Stroke({
                    color: [102, 166, 30, 0.5],
                    width: 8
                })
            })
        }
    });
}

export function cstationDefaultStyle(){
    st_vt_ly.setStyle(function () {
        return new Style({
            stroke: new Stroke({
                color: [102, 166, 30, 0.5],
                width: 8
            })
        })
    })
}

export function streetDefaultStyle(){
    st_vt_ly.setStyle(function () {
        return new Style({
            stroke: new Stroke({
                color: [1, 1, 1, 0.6],
                width: 8
            })
        })
    })
    st2021_vt_ly.setStyle(function () {
        return new Style({
            stroke: new Stroke({
                color: [1, 1, 1, 0.6],
                width: 8
            })
        })
    })
    st2023_vt_ly.setStyle(function () {
        return new Style({
            stroke: new Stroke({
                color: [1, 1, 1, 0.6],
                width: 8
            })
        })
    })
}

export const defaultroadselectionstyle = new Style({
    stroke: new Stroke({
        color: [0, 131, 154, 1],
        width: 12
    })
});

export function linestringstyleFunction (feature) {
    return new Style({
        stroke: new Stroke({
            color: [103,169,207],
            width: 8
        })
    })
}

export function linestringstyleFunction2021 (feature) {
    return new Style({
        stroke: new Stroke({
            color: [239,138,98],
            width: 14
        })
    })
}

export function linestringstyleFunction2023 (feature) {
    return new Style({
        stroke: new Stroke({
            color: [151,201,34],
            width: 14
        })
    })
}