import $ from 'jquery'
import Tabulator from "../../node_modules/tabulator-tables/dist/js/tabulator"
import {tableDownload} from "../insights/insightsFunctions";

let audienceSearchTable = null
let marketSearchTable = null

export function createAudienceSearchTable(data){
    // console.log(data)
    $("#segmentReferenceSpinner").attr('hidden',true)
    audienceSearchTable = new Tabulator ("#segment_reference_table", {
        maxHeight:"20rem",
        layout:"fitDataFill",
        layoutColumnsOnNewData:true,
        selectable: 1,
        data: data,
        columns:[
            {
                field: "segment_type_id",
                title: "ID",
                sorter: "number"
            },
            {
                field: "segment_type_description",
                title: "Description"
            },
            {
                field: "segment_type_audience_id",
                title: "Database ID"
            },
        ]
    })
}

export function createMarketSearchTable(data){
    // console.log(data)
    $("#segment_reference_table").attr('hidden',false)
    $("#marketResultsHeader").attr('hidden',false)
    $("#marketResultsDownload").attr('hidden',false)
    $("#marketReferenceSpinner").attr('hidden',true)
    $("#marketResultsDownload").click(function (){tableDownload(marketSearchTable,"marketSearchResults")})
    marketSearchTable = new Tabulator ("#market_reference_table", {
        maxHeight:"20rem",
        layout:"fitDataFill",
        layoutColumnsOnNewData:true,
        selectable: 1,
        data: data,
        autoColumns: true
    })
}
