import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import Feature from "ol/Feature";
import {VectorTile as VectorTileLayer} from "ol/layer";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import {inventoryClassStyler} from "../map_styles/pointStyles";

export function vttileload(tile, url) {
    // console.log(tile,url)
    tile.setLoader(function(extent, resolution, projection) {
        fetch(url,{headers:{"Authorization": "Bearer " + localStorage.getItem('access_token')}}).then(function(response) {
            response.arrayBuffer().then(function(data) {
                const format = tile.getFormat() // ol/format/MVT configured as source format
                const features = format.readFeatures(data, {
                    extent: extent,
                    featureProjection: projection
                });
                tile.setFeatures(features);
            });
        });
    });
}


export const gp_inv_vt_min = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getInvTile/{z}/{x}/{y}.pbf',
    cacheSize: 0,
    tileLoadFunction: vttileload
});

export const gp_inv_vt_decom = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getInvDecomTile/{z}/{x}/{y}.pbf',
    cacheSize: 0,
    tileLoadFunction: vttileload
});

export const st21_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getSt21Tile/{z}/{x}/{y}.pbf',
    tileLoadFunction: vttileload
});

export const st22_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getSt22Tile/{z}/{x}/{y}.pbf',
    tileLoadFunction: vttileload
});

export const st23_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getSt23Tile/{z}/{x}/{y}.pbf',
    tileLoadFunction: vttileload
});

export const st_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getStTile/{z}/{x}/{y}.pbf',
    tileLoadFunction: vttileload
});

export const place_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getPlaceTile/{z}/{x}/{y}.pbf',
    tileLoadFunction: vttileload
});

const cstation_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.appurl +'/api/getCstationTile/{z}/{x}/{y}.pbf',
    tileLoadFunction: vttileload
});

const pctransit_vt = new VectorTileSource({
    format: new MVT({
        featureClass: Feature
    }),
    url: process.env.GeoServerURL +'/geoserver/gwc/service/tms/1.0.0/' +
        'geopath%3Anational2016@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf',
});

export const st_vt_ly = new VectorTileLayer({
    title: 'HERE2016r3 Streets',
    name: 'HERE2016r3',
    declutter: false,
    source: st_vt,
    zIndex:1,
    minZoom: 17.9,
    maxZoom: 19,
    style: new Style({
        stroke: new Stroke({
            color: [1, 1, 1, 0.6],
            width: 8
        })
    })

});

export const st2021_vt_ly = new VectorTileLayer({
    title: 'HERE2021r1 Streets',
    name: 'HERE2021r1',
    declutter: false,
    source: st21_vt,
    zIndex:1,
    visible: false,
    minZoom: 17.9,
    maxZoom: 19,
    style: new Style({
        stroke: new Stroke({
            color: [1, 1, 1, 0.6],
            width: 8
        })
    })

});

export const st2023_vt_ly = new VectorTileLayer({
    title: 'OSM20230808 Streets',
    name: 'OSM20230808',
    declutter: false,
    source: st23_vt,
    zIndex:5,
    visible: false,
    minZoom: 17.9,
    maxZoom: 19,
    style: new Style({
        stroke: new Stroke({
            color: [1, 1, 1, 0.6],
            width: 8
        })
    })

});

// export const st2022_vt_ly = new VectorTileLayer({
//     title: 'HERE2022r1 Streets',
//     name: 'HERE2022r1',
//     declutter: false,
//     source: st21_vt,
//     visible: false,
//     minResolution: 0.28,
//     maxResolution: 5,
//     style: new Style({
//         stroke: new Stroke({
//             color: [1, 1, 1, 0.6],
//             width: 8
//         })
//     })
//
// });

export const place_vt_ly = new VectorTileLayer({
    title: 'Geopath Places',
    name: 'Places',
    declutter: false,
    source: place_vt,
    visible: false,
    minZoom: 17.9,
    maxZoom: 19,
    style: new Style({
        stroke: new Stroke({
            color: [0, 131, 154],
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    }),
});

export const cstation_vt_ly = new VectorTileLayer({
    title: 'TAB countstations',
    declutter: false,
    source: cstation_vt,
    visible: false,
    minZoom: 17.9,
    maxZoom: 19,
    style: new Style({
        stroke: new Stroke({
            color: [102, 166, 30, 0.5],
            width: 8
        })
    })
});
// export const pctransit_vt_ly = new VectorTileLayer({
//     title: 'PC Transit',
//     declutter: false,
//     source: pctransit_vt,
//     visible: false,
//     minResolution: 17.9,
//     maxResolution: 19,
//     style: new Style({
//         stroke: new Stroke({
//             color: [102, 166, 30, 0.5],
//             width: 8
//         })
//     })
// });

export const gp_inv_vt_ly_min = new VectorTileLayer({
    title: 'Geopath Inventory',
    visible: true,
    declutter: false,
    source: gp_inv_vt_min,
    zIndex:4,
    minZoom: 17.9,
    maxZoom: 19,
    style: inventoryClassStyler
});

export const gp_inv_vt_ly_decom = new VectorTileLayer({
    title: 'Decommisioned / Inactive  Inventory',
    visible: true,
    declutter: false,
    source: gp_inv_vt_decom,
    zIndex:4,
    minZoom: 17.9,
    maxZoom: 19,
    style: new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: [178,34,34, 1]
            }),
            stroke: new Stroke({
                color: [55, 56, 58, 1]
            })
        })
    })
});


export function refreshInvVectorTile() {
    gp_inv_vt_min.clear()
}