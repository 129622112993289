import $ from "jquery";
import {createAudienceSearchTable, createMarketSearchTable} from "../infoDisplays/referenceTables";
import {clearRedundantFields} from "../supportFunctions/consts";
import {searchFrameId, searchSpotId} from "./frameSearch";

export function searchAudienceSegemnts() {
    $("#segmentReferenceSpinner").attr('hidden',false)
    $("#marketResultsHeader").attr('hidden',true)
    $("#marketResultsDownload").attr('hidden',true)
    $("#segment_reference_table").attr('hidden',true)
    let settings = {
        "url": process.env.appurl + "/api/searchAudiences/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "search_string": $("#segmentReferenceInput").val()
        }
    }
    $.ajax(settings).done( function (response) {
        createAudienceSearchTable(response)
    })
}

export function searchMarkets() {
    $("#marketReferenceSpinner").attr('hidden',false)
    $("#market_reference_table").attr('hidden',false)
    let settings = {
        "url": process.env.appurl + "/api/searchMarkets/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "term": $("#marketReferenceInput").val(),
            "type": $("input:radio.marketType:checked").val()
        }
    }
    $.ajax(settings).done( function (response) {
        createMarketSearchTable(response['data']['markets'])
    })
}

export function updateLatLonTool (){
    $("#latlonloadingspinner").attr('hidden',false)
    let frameid = $("#latlonframeid").val()
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getFrameInfo',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data":{
            "geopath_id" : frameid
        }
    };
    $.ajax(settings).done(function (response) {
        let frameObj = response['data']
        // console.log(frameObj)
        frameObj["location"]["longitude"] = parseFloat($("#lonfixer").val());
        frameObj["location"]["latitude"] = parseFloat($("#fixerLat").val());
        frameObj['updated_desc'] = "coordinates updated after error";
        clearRedundantFields(frameObj);
        // console.log(frameObj)
        let frameString = JSON.stringify(frameObj);
        let settings1 = {
            "url": process.env.appurl + "/api/updateFrame/",
            "method": "post",
            "content-type": "application/json",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Geopath-User-Service-Account": localStorage.getItem('userEmail')
            },
            "data": {"frameObj": frameString}
        };
        $.ajax(settings1).done(function (response){
            if (response === 'OK'){
                alert('Success, Frame Updated')
                $("#latlonloadingspinner").attr('hidden',true)
                $("#lonfixer").val('')
                $("#fixerLat").val('')
                $("#latlonframeid").val('')
            }
            else {
                $("#latlonToolMsg").text(response)
                $("#latlonloadingspinner").attr('hidden',true)
            }
        })

    })

}

export function lookupSpotID(){
    $("#lookupMsg").html('')
    let spotID = $("#lookupToolInput").val()
    $.ajax(searchSpotId(spotID)).done( function (response) {
        $("#lookupMsg").html(
            '<table class="table table-striped table-bordered table-sm">'+
            '<tbody>' +
            '   <tr>' +
            '      <th scope="row">Frame ID</th>' +
            '      <td>' + response[0]['frame_id'] + '&nbsp<button class="clipboard" id="frameidcopy" data-clipboard-text="' + response[0]['frame_id'] + '"><i class="far fa-clipboard"></i></button></td>' +
            '   </tr>' +
            '</tbody>'
        )
    })
}

export function lookupFrameID(){
    $("#lookupMsg").html('')
    let frameid = $("#lookupToolInput").val()
    $.ajax(searchFrameId(frameid)).done( function (response) {
        $("#lookupMsg").html(
            '<table class="table table-striped table-bordered table-sm">'+
            '<tbody id ="lookupFrameTable"></tbody>'
        )
        response.sort(function (a,b){
            return a.spot_id - b.spot_id
        })
        $.each(response, function (index,value){
            $("#lookupFrameTable").append(
                '   <tr>' +
                '      <th scope="row">Spot ID</th>' +
                '      <td>' + value['spot_id'] + '&nbsp<button class="clipboard"  data-clipboard-text="' + value['spot_id'] + '"><i class="far fa-clipboard"></i></button></td>' +
                '   </tr>'
            )
        })

    })
}