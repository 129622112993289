import $ from 'jquery'
import {TabulatorFull as Tabulator} from "tabulator-tables";
import {accounts_under_review, getAppealedAccounts} from "../supportFunctions/accounts";

export async function saveAccountReview(rows){
    let accessToken = "Bearer " + localStorage.getItem('access_token');
    let newvals = []
    $.each(rows, function (index,value){
        value = {'account_id':parseFloat(value['account_id'])}
        newvals.push (value)
    })
    let deletesettings = {
        "url": process.env.appurl + "/api/removefromreview/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": accessToken
        },
    };
    $.ajax(deletesettings).done(function (response) {
        createnewrecords(newvals)
    })
}

export async function createnewrecords(newvals) {
    let createPromises = []
    let getaccountpromises =[]
    let accessToken = "Bearer " + localStorage.getItem('access_token');
    $.each(newvals, function (index, value) {
        createPromises.push(new Promise(function (resolve) {
            let createsettings = {
                "url": process.env.appurl + "/api/addtoreview/",
                "method": "get",
                "content-type": "application/json",
                "headers": {
                    "Authorization": accessToken
                },
                "data": {
                    "account_id": value['account_id']
                }
            }
            $.ajax(createsettings).done(function (response) {
                resolve()
            })
        }))
    })
    await Promise.all(createPromises).then(function () {
        getAppealedAccounts()
        alert('Hello. ' +
                'You have successfully updated the table.' +
                'Have a great day!')
        })
}

export async function createAccountReviewTable() {
    let accountList = accounts_under_review
    $("#accountReviewTable").html('')
    $("#accountReviewHeader").html('')
    $("#accountReviewHeader").html('<h5>Set Accounts Under Review</h5>')
    let deleteicon = function(cell, formatterParams, onRendered){ //plain text value
        return "<i class='fas fa-trash-alt'></i>";
    };
    let accountReviewTabulator = new Tabulator('#accountReviewTable', {
        data:accountList,
        layout: "fitColumns",
        columns:[
            {
                title: "Account ID",
                field: "account_id",
                editor:"input",
            },
            {
                formatter:deleteicon,
                width:40,
                hozAlign:"center",
                cellClick:function(e, cell){accountReviewTabulator.deleteRow(cell.getRow())}
            },
        ],
        footerElement:'<button id="addAccountReviewBtn">Add Account</button><button id="saveReviewBtn">Save</button><button id="refreshReviewBtn"><i class="fas fa-sync"></i></button>',
    })
    accountReviewTabulator.on('tableBuilt', function (){
        $("#addAccountReviewBtn").on('click',function (){
            accountReviewTabulator.addRow()
        })
        $("#saveReviewBtn").on('click',function (){
            saveAccountReview(accountReviewTabulator.getData())
        })
        $("#refreshReviewBtn").on('click',function (){
            createAccountReviewTable()
        })
    })

}