import $ from 'jquery'
import {st2021_vt_ly, st_vt_ly, st2023_vt_ly} from "../layers/vectorTileLayers.js";

//create scaffolding for the create assignment form
export function createAssignmentForm () {

    $("#submaprow").html(
        '<form name="createAssignment">' +
        '<div class="form-group form-inline align-items-center">' +
        '<input type= "hidden" id="selectedLineWKT">' +
        '<input type= "hidden" id="segLanes">' +
        '<input type= "hidden" id="selectedLineOppseg">' +
        '<input type= "hidden" id="selectedLineOppseggeom">' +
        '<input type= "hidden" id="oppSegLanes">' +
        '<input type= "hidden" id="userid">' +
        '<div class="col-sm">' +
        '<div class="row">' +
        '<label class="col" for="geopathIDinput">Geopath Frame ID</label>' +
        '<input name="geopathid" type="text"  id="geopathIDinput" readonly value='+$('#currentGeoID').text()+'>' +
        '</div>' +
        '<div class="row">' +
        '<label class="col" for="segListForm"> Segment ID </label>' +
        '<input type="text" name= "segmentid" id="segListForm">' +
        '</div>' +
        '</div>' +
        '<div class="col-sm">' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" name= "type" type="radio" id="bothval" value="B" checked="checked">' + '  ' +
        '<label class="form-check-label text-nowrap" for="bothval">Both</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" name= "type" type="radio" id="pedonly" value="P">' +
        '<label class="form-check-label text-nowrap" for="pedonly">Ped Only</label>' +
        '</div>' +
        '<div class="form-check form-check-inline">' +
        '<input class="form-check-input" name= "type" type="checkbox" id="center_read" value="TRUE">' +
        '<label class="form-check-label" for="center_read">Center Read</label>' +
        '</div>' +
        '</div>' +
        // '<div class="col-sm">' +
        // '<div class="form-check form-check-inline">' +
        // '<input class="form-check-input" name= "network" type="radio" id="year2016" value="2016">' + '  ' +
        // '<label class="form-check-label text-nowrap" for="year2016">2016 Network</label>' +
        // '</div>' +
        // '<div class="form-check form-check-inline">' +
        // '<input class="form-check-input" name= "network" type="radio" disabled="true" id="year2021" value="2021">' +
        // '<label class="form-check-label text-nowrap" for="year2013">2021 Network</label>' +
        // '</div>' +
        // '<div class="form-check form-check-inline">' +
        // '<input class="form-check-input" name= "network" type="radio" id="year2023" value="2023">' +
        // '<label class="form-check-label text-nowrap" for="year2013">2023 Network</label>' +
        // '</div>' +
        // '</div>' +
        '<div class="col-sm">' +
        '<button id="addToAssignmentCart" type="button" class="btn btn-secondary">Add</button>' +
        '</div>' +
        '<div class="col-sm" style="width: 300px; overflow-x: auto">' +
        '<ul class ="list-group" id="assignmentFormList" style="display: inline-block; white-space: nowrap;"></ul>' +
        '</div>' +
        '<div class="col-sm">' +
        '<button id="submitnewassignment" type="button" class="btn btn-secondary">Submit</button>' +
        '</div>' +
        '<div class="col-sm">' +
        '<button id="exitcreateform" type="button" class="btn btn-secondary">Exit</button>' +
        '</div>' +
        '</div>' +
        '</form>'
    )
    // if(st_vt_ly.getVisible()){
    //     $('#year2016').attr('checked',true)

    // }
    // else if (st2021_vt_ly.getVisible()){
    //     $('#year2021').attr('checked',true)
    // }
    // else if (st2023_vt_ly.getVisible()){
    //     $('#year2023').attr('checked',true)
    // }
}
