import $ from "jquery";
import {clearMapUtilsBody, clearMapUtilsHeader, clearRedundantFields} from "../supportFunctions/consts";
import {transformInteraction} from "../map";
import {rotateSource} from "../layers/activeLayers";
import {selectedInventory} from "../map_styles/pointStyles";
import {apiGetFrame, refreshFrameJson} from "./pointHandler";
import {checkAccountForAppeal} from "../supportFunctions/accounts";

export function checkOutFrame() {
    return new Promise((resolve) => {
        let frameObj = JSON.parse($('#panelInfoDropDownText').text());
        let user = localStorage.getItem('userEmail');
        let accessToken = "Bearer " + localStorage.getItem('access_token');
        if (frameObj["status_type"]["id"] !== 90) {
            let auditLogSettings = {
                "url": process.env.appurl + "/api/addToAuditLog/",
                "method": "get",
                "content-type": "application/json",
                "headers": {
                    "Authorization": accessToken,
                },
                "data": {"frame_id": frameObj['id'], "status": frameObj["status_type"]["id"], "user": user}
            };
            $.ajax(auditLogSettings).done(function (response) {
                // console.log('checking out frame')
                if (response === 'OK') {
                    parseFloat(frameObj["status_type"]["id"] = 90);
                    frameObj['updated_desc'] = 'checked out via atlas for spatial editing';
                    clearRedundantFields(frameObj);
                    let frameString = JSON.stringify(frameObj);
                    let settings = {
                        "url": process.env.appurl + "/api/updateFrame/",
                        "method": "post",
                        "content-type": "application/json",
                        "headers": {
                            "Authorization": accessToken,
                            "Geopath-User-Service-Account": user
                        },
                        "data": {"frameObj": frameString}
                    };
                    $.ajax(settings).done(function (response) {
                            if (response === 'OK') {
                                // console.log('refreshing api info after checking out frame')
                                refreshFrameJson(frameObj['id'])
                                resolve(true)
                            } else {
                                resolve(false)
                            }
                        }
                    )
                }
            })
        } else {
            resolve(false)
        }
    })
}

export function getExisitngStatus(frame_id) {
    return new Promise((resolve) => {
        let settings = {
            "url": process.env.appurl + "/api/getAuditStatus/",
            "method": "get",
            "content-type": "application/json",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            "data": {"frame_id": frame_id}
        };
        $.ajax(settings).done(function (response) {
            if (response[0]) {
                resolve(response[0]['original_status_type_sk'])
            } else {
                resolve(9)
            }

        })
    });
}

export async function checkInFrameWithoutEdit() {
    let frameObj = JSON.parse($('#panelInfoDropDownText').text());
    let accessToken = "Bearer " + localStorage.getItem('access_token');
    let existingStatus = frameObj["status_type"]["id"];
    if (existingStatus === 90) {
        existingStatus = await getExisitngStatus(frameObj['id']);
    }
    parseFloat(frameObj["status_type"]["id"] = existingStatus);
    frameObj['updated_desc'] = 'checked in via atlas without any edits to spatial attributes';
    clearRedundantFields(frameObj)
    let frameString = JSON.stringify(frameObj);
    let settings = {
        "url": process.env.appurl + "/api/updateFrame/",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": accessToken,
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data": {"frameObj": frameString}
    };
    $.ajax(settings).done(function (response) {
        removeFromAuditLog()
        clearMapUtilsBody()
        clearMapUtilsHeader()
        // console.log('refreshing api info after checking in without edit')
        apiGetFrame(frameObj['id'])
    })
}

export function removeFromAuditLog() {
    let frameObj = JSON.parse($('#panelInfoDropDownText').text());
    let auditLogSettings = {
        "url": process.env.appurl + "/api/removeFromAuditLog/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {"frame_id": frameObj['id']}
    };
    $.ajax(auditLogSettings).done(function (response) {
        selectedInventory(frameObj['id']);
        transformInteraction.setActive(false);
        return response.status
    })
}

export function updateFrameStatusAfterAssignment(updated_desc) {
    let frameObj = JSON.parse($('#panelInfoDropDownText').text());
    // console.log(frameObj)
    if (checkAccountForAppeal(frameObj['representations'][0]['account']['id']) === true) {
        frameObj["status_type"]["id"] = 54;
        frameObj['updated_desc'] = "update made in atlas and frame held for appeal";
    } else {
        frameObj["status_type"]["id"] = statusActionLookup(frameObj['status_type']['id'], "assignment");
        frameObj['updated_desc'] = updated_desc;
    }
    clearRedundantFields(frameObj);
    let frameString = JSON.stringify(frameObj);
    let settings = {
        "url": process.env.appurl + "/api/updateFrame/",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data": {"frameObj": frameString}
    };
    $.ajax(settings).done(function (response) {
        removeFromAuditLog()
        rotateSource.clear();
        transformInteraction.setActive(false);
        // console.log('refreshing api info after assignment')
        apiGetFrame(frameObj['id'])

    })
}

export async function updateFrameStatusAfterBulkAssignment(updated_desc,frame_id) {
    // console.log(frame_id)
    let frameidPromises = []
    let frameObj = null
    frameidPromises.push(new Promise(function (resolve) {
        let formData = {
            "geopath_id": frame_id
        };
        let settings = {
            "async": true,
            "crossDomain": true,
            "url": process.env.appurl + '/api/getFrameInfo',
            "method": "GET",
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded/",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            "data": formData
        };
        $.ajax(settings).done(function (response) {
            // console.log(response)
            frameObj = response['data']
            resolve()
        })
    }))
    await Promise.all(frameidPromises).then(function (){
        // console.log(frameObj)
        frameObj["status_type"]["id"] = 31;
        frameObj['updated_desc'] = updated_desc;
        clearRedundantFields(frameObj);
        let frameString = JSON.stringify(frameObj);
        // console.log(frameObj)
        let settings = {
            "url": process.env.appurl + "/api/updateFrame/",
            "method": "post",
            "content-type": "application/json",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Geopath-User-Service-Account": localStorage.getItem('userEmail')
            },
            "data": {"frameObj": frameString}
        };
        $.ajax(settings).done(function (response) {
            // console.log(response)
        })
    }

)

}

// export function updateFrameStatusfromButtons(action){
//     let frameObj = JSON.parse($('#panelInfoDropDownText').text());
//     if(action === 'badGeocode'){
//         frameObj["status_type"]["id"] = 39;
//         frameObj["updated_desc"] = "location could not be confirmed, operator needs to review"
//     }
//     else if(action === 'badOrientation'){
//         frameObj["status_type"]["id"] = 39;
//         frameObj["updated_desc"] = "orientation appears incorrect, operator needs to review"
//     }
//     else if(action === 'badType'){
//         frameObj["status_type"]["id"] = 50;
//         frameObj["updated_desc"] = "location not measurable under roadside methodology"
//     }
//     else if(action === 'badTraffic'){
//         frameObj["status_type"]["id"] = 39;
//         frameObj["updated_desc"] = "traffic count seems low and should be reviewed by citilabs"
//     }
//     else if(action === 'noIssue'){
//         if (frameObj["status_type"]["id"] === 52){
//             frameObj["status_type"]["id"] = 51;
//             frameObj["updated_desc"] = "Review has been completed and no changes appear necessary, moved to measured"
//         }
//         else {
//             frameObj["status_type"]["id"] = 31;
//             frameObj["updated_desc"] = "Review has been completed and no changes appear necessary, moved to be re-measured"
//         }
//     }
//     clearRedundantFields(frameObj);
//     let frameString = JSON.stringify(frameObj);
//     let settings = {
//         "url": process.env.appurl + "/api/updateFrame/",
//         "method": "post",
//         "content-type": "application/json",
//         "headers": {
//             "Authorization": "Bearer " + localStorage.getItem('access_token'),
//             "Geopath-User-Service-Account": localStorage.getItem('userEmail')
//         },
//         "data":{"frameObj":frameString}
//     };
//     $.ajax(settings).done(function (response) {
//         document.getElementById('assignmentsbutton').innerHTML = '';
//         document.getElementById('editorSave').innerHTML = '';
//         rotateSource.clear();
//         transformInteraction.setActive(false);
//         specificpanel()
//     })
// }

export function statusActionLookup(currentStatusID, actionType, account_id) {
    if (currentStatusID === 90) {

    }
    let appealstatus = checkAccountForAppeal(account_id)
    // console.log(appealstatus)
    let newStatusID
    // console.log(currentStatusID)
    // console.log(actionType)
    if (actionType === 'spatial') {
        if (appealstatus){
            newStatusID = 54
        }
        else if (currentStatusID === 10) {
            newStatusID = 20
        } else if (currentStatusID === 20) {
            newStatusID = 20
        } else if (currentStatusID === 30) {
            newStatusID = 20
        } else if (currentStatusID === 31) {
            newStatusID = 20
        } else if (currentStatusID === 40) {
            newStatusID = 40
        } else if (currentStatusID === 50) {
            newStatusID = 50
        } else if (currentStatusID === 51) {
            newStatusID = 20
        } else if (currentStatusID === 52) {
            newStatusID = 20
        } else if (currentStatusID === 53) {
            newStatusID = 53
        } else if (currentStatusID === 90) {
            newStatusID = 90
        } else if (currentStatusID === 39) {
            newStatusID = 20
        } else if (currentStatusID === 59) {
            newStatusID = 59
        } else if (currentStatusID === 80) {
            newStatusID = 80
        } else if (currentStatusID === 49) {
            newStatusID = 49
        } else if (currentStatusID === 32) {
            newStatusID = 20
        } else if (currentStatusID === 33) {
            newStatusID = 20
        } else if (currentStatusID === 34) {
            newStatusID = 20
        } else if (currentStatusID === 35) {
            newStatusID = 20
        } else if (currentStatusID === 36) {
            newStatusID = 20
        } else if (currentStatusID === 54) {
            newStatusID = 20
        } else {
            newStatusID = 20
        }

    }
    else if (actionType === 'assignment') {
        if (appealstatus){
            newStatusID = 54
        }
        else if (currentStatusID === 10) {
            newStatusID = 20
        } else if (currentStatusID === 20) {
            newStatusID = 31
        } else if (currentStatusID === 30) {
            newStatusID = 31
        } else if (currentStatusID === 31) {
            newStatusID = 31
        } else if (currentStatusID === 40) {
            newStatusID = 40
        } else if (currentStatusID === 50) {
            newStatusID = 50
        } else if (currentStatusID === 51) {
            newStatusID = 31
        } else if (currentStatusID === 52) {
            newStatusID = 31
        } else if (currentStatusID === 53) {
            newStatusID = 53
        } else if (currentStatusID === 90) {
            newStatusID = 31
        } else if (currentStatusID === 39) {
            newStatusID = 20
        } else if (currentStatusID === 59) {
            newStatusID = 59
        } else if (currentStatusID === 80) {
            newStatusID = 80
        } else if (currentStatusID === 49) {
            newStatusID = 49
        } else if (currentStatusID === 32) {
            newStatusID = 31
        } else if (currentStatusID === 33) {
            newStatusID = 31
        } else if (currentStatusID === 34) {
            newStatusID = 31
        } else if (currentStatusID === 35) {
            newStatusID = 31
        } else if (currentStatusID === 36) {
            newStatusID = 31
        } else if (currentStatusID === 54) {
            newStatusID = 54
        } else {
            newStatusID = 20
        }

    }
    else {
        if (appealstatus){
            newStatusID = 54
        }
        else if (currentStatusID === 10) {
            newStatusID = 20
        } else if (currentStatusID === 20) {
            newStatusID = 20
        } else if (currentStatusID === 30) {
            newStatusID = 20
        } else if (currentStatusID === 31) {
            newStatusID = 31
        } else if (currentStatusID === 40) {
            newStatusID = 40
        } else if (currentStatusID === 50) {
            newStatusID = 50
        } else if (currentStatusID === 51) {
            newStatusID = 31
        } else if (currentStatusID === 52) {
            newStatusID = 31
        } else if (currentStatusID === 53) {
            newStatusID = 53
        } else if (currentStatusID === 90) {
            newStatusID = 90
        } else if (currentStatusID === 39) {
            newStatusID = 39
        } else if (currentStatusID === 59) {
            newStatusID = 59
        } else if (currentStatusID === 80) {
            newStatusID = 80
        } else if (currentStatusID === 49) {
            newStatusID = 49
        } else if (currentStatusID === 32) {
            newStatusID = 32
        } else if (currentStatusID === 33) {
            newStatusID = 33
        } else if (currentStatusID === 34) {
            newStatusID = 34
        } else if (currentStatusID === 35) {
            newStatusID = 35
        } else if (currentStatusID === 36) {
            newStatusID = 36
        } else if (currentStatusID === 54) {
            newStatusID = 54
        } else {
            newStatusID = 20
        }

    }
    // console.log(newStatusID)
    return newStatusID
}