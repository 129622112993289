import $ from 'jquery'
import {
    _blank,
    clearInfoDiv,
    clearOtherInfoDiv,
    clearSubMapRow,
    currentpanelgeojson,
    hideAssignManagementButtons,
    hideAssignmentsButton,
    hideAssignmentTable,
    hideInfoLoading,
    setAssignmentModeToFalse,
    showAssignmentsButton,
    showInfoLoading
} from "../supportFunctions/consts";
import {prepFrameInfoDisplay} from "../infoDisplays/frameInfo";
import {clearAssignmentLayer, clearRotateLayer} from "../layers/activeLayers";
import {streetDefaultStyle} from "../map_styles/lineStyle";
import nophotothumb from '../assets/no_photo_thumb.png';
import {selectedFilteredInventory, selectedInventory} from "../map_styles/pointStyles";
import {createPointWKTfromCoords, transform4326to3857} from "../supportFunctions/mapFunctions";

export function refreshFrameJson(frame_id){
    let formData = {
        "geopath_id": frame_id
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getFrameInfo',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": formData
    };
    $.ajax(settings).done(function (response) {
        // console.log('setting new json')
        currentpanelgeojson.text(JSON.stringify(response.data))
        // console.log(currentpanelgeojson.val())
    })
}

//for main mapping tool this function gets the frame info from api and sets up downstream workflow items
export function apiGetFrame(frame_id) {
    // console.log('current frame json')
    // console.log(currentpanelgeojson.val())
    $("#currentpanelstuff").attr('hidden',false);
    let formData = {
        "geopath_id": frame_id
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getFrameInfo',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": formData
    };
    showInfoLoading();
    $("#currentpanelid").text(frame_id);
    $.ajax(settings).done(function (response) {
        clearOtherInfoDiv()
        currentpanelgeojson.text(JSON.stringify(response.data))
        prepFrameInfoDisplay(response['data'])
        showAssignmentsButton()
        let newcoords = transform4326to3857(response['data']['location']['longitude'],response['data']['location']['latitude'])
        createPointWKTfromCoords(newcoords[0],newcoords[1])
        hideInfoLoading();
    })
}

//for NON mapping this function gets the frame info from api only
export function apiGetFrameOnly(frame_id) {
    // console.log('current frame json')
    // console.log(currentpanelgeojson.val())
    let formData = {
        "geopath_id": frame_id
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getFrameInfo',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": formData
    };
    $.ajax(settings).done(function (response) {
        return response
    })
}

// once the frame feature is selected, parse values and load info into various containers
export function currentFrameParse(feature){
    let props = feature['values_']
    let selectedFrameID = props['frame_id']
    // let frameWKTGeom = new WKT().writeFeature(feature);
    clearRotateLayer();
    //set map style
    if(props['layer']==="inventory_min_view"){
        selectedInventory(selectedFrameID)
    }
    else{
        selectedFilteredInventory(selectedFrameID)
    }
    selectedInventory(selectedFrameID)
    $("#currentpanelstuff").attr('hidden',false);
    //go get detailed info
    apiGetFrame(selectedFrameID)
}

export function pointHandler(allfeatures) {
    let features = allfeatures
    let numfeats = features['length']
    let i =0
    //paginate results if more than 1
    if (numfeats > 1) {
        $("#mapUtilsHeader").prop('hidden',false)
        $("#mapUtilsHeader").html('<h6>'+numfeats +' Features Selected</h6>')
        $("#nextbutton").html('<button id="infonext" type="button" class="btn btn-primary btn-sm">Next</button>');
        $("#prevbutton").html('<button id="infoprev" type="button" class="btn btn-primary btn-sm" disabled>Previous</button>');
    }
    // set rules for paging through results
    $('#infonext').on('click', function () {
        clearAssignmentLayer()
        clearInfoDiv()
        clearOtherInfoDiv()
        setAssignmentModeToFalse()
        clearSubMapRow()
        hideAssignManagementButtons()
        hideAssignmentTable()
        hideAssignmentsButton()
        streetDefaultStyle()
        i++
        $('#infoprev').prop('disabled', false)
        currentFrameParse(features[i])
        if ((i + 1) >= numfeats) {
            $('#infonext').prop('disabled', true)
        }
    })
    $('#infoprev').on('click', function () {
        clearAssignmentLayer()
        clearInfoDiv()
        clearOtherInfoDiv()
        setAssignmentModeToFalse()
        clearSubMapRow()
        hideAssignManagementButtons()
        hideAssignmentTable()
        hideAssignmentsButton()
        streetDefaultStyle()

        i--
        if (i === 0) {
            $('#infoprev').prop('disabled', true);
            $('#infonext').prop('disabled', false);
        }
        currentFrameParse(features[i])
    })

    currentFrameParse(features[i])
}

//get photos for inventory based on the photo object in the api return
export function getPhotos(photoObject) {
    let nophoto = nophotothumb;
    let thumburl
    let largeurl
    //if nothing in object use default photo
    if (photoObject.length === 0){
        $("#framePhoto").html('<img src="' + nophoto + '">')
    }
    else {
        thumburl = photoObject[2]
        largeurl = photoObject[0]
        $.get(thumburl)
            .done(function () {
                $("#framePhoto").html(
                    "<a href=" + largeurl + " target=" + _blank + ">" + "<img src=" + thumburl + ">" + '<br>'
                );
            }).fail(function () {
            $("#framePhoto").html('<img src="' + nophoto + '">')
        })
    }

}


