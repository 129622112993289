import $ from 'jquery'
import {classificationSelectVals, statusSelectVals} from "../supportFunctions/apiTypes";
import Papa from 'papaparse'
import {clearRedundantFields} from "../supportFunctions/consts";
import {makePlaceBasedAssignments} from "../assignments/assignmentActions";

export function populateClassificationTypes() {
    $("#classChangeDropDown").html('').select2({
        placeholder: "Select Classification Type",
        data: classificationSelectVals
    })
}

export function populateStatusTypes(selector) {
    // console.log(statusSelectVals)
    selector.html('').select2({
        theme: 'bootstrap4',
        placeholder: "Select Status Type",
        data: statusSelectVals
    })
}


export async function loadclassificationCSV(evt) {
    $("#classChangeCount").html('')
    $("#classChangeProgressMsg").html('')
    $("#classChangeBtn").attr('hidden', true)
    let file = evt.target.files[0];
    let framelist = []
    let frameparsePromises = []
    Papa.parse(file, {
        dynamicTyping: true,
        complete: async function (results) {
            // console.log(results)
            $.each(results.data, function (index, value) {
                frameparsePromises.push(new Promise(function (resolve) {
                    // console.log(value)
                    if (value[0]) {
                        framelist.push(value[0])
                    }
                    resolve()
                }))
            })
            await Promise.all(frameparsePromises).then(function () {
                // console.log(framelist)
                $("#classChangeCount").html('<span>Total Frames to Adjust: ' + framelist.length + '</span>')
                $("#classChangeBtn").attr('hidden', false)
                $("#classChangeBtn").on("click", function () {
                    changeClassification(framelist, $("#classChangeDropDown").select2('data'))
                })
            })
        }
    })

}

export async function loadstatusCSV(evt) {
    $("#statusChangeCount").html('')
    $("#statusChangeProgressMsg").html('')
    $("#statusChangeBtn").attr('hidden', true)
    let file = evt.target.files[0];
    let framelist = []
    let frameparsePromises = []
    Papa.parse(file, {
        dynamicTyping: true,
        complete: async function (results) {
            // console.log(results)
            $.each(results.data, function (index, value) {
                frameparsePromises.push(new Promise(function (resolve) {
                    // console.log(value)
                    if (value[0]) {
                        framelist.push(value[0])
                    }
                    resolve()
                }))
            })
            await Promise.all(frameparsePromises).then(function () {
                // console.log(framelist)
                $("#statusChangeCount").html('<span>Total Frames to Adjust: ' + framelist.length + '</span>')
                $("#statusChangeBtn").attr('hidden', false)
                $("#statusChangeBtn").on("click", function () {
                    changeStatus(framelist, $("#statusChangeDropDown").select2('data'))
                })
            })
        }
    })

}

export async function changeClassification(frames, idvalue) {
    $("#classChangeSpinner").attr("hidden", false)
    let newclassid = idvalue[0]['id']
    let updatePromises = []
    let successUpdates = []
    let failedUpdate = []
    $.each(frames, function (index, value) {
        updatePromises.push(new Promise(resolve => {
            let getSettings = {
                "async": false,
                "url": process.env.appurl + '/api/getFrameInfo',
                "method": "GET",
                "headers": {
                    "Content-Type": "application/x-www-form-urlencoded/",
                    "Authorization": "Bearer " + localStorage.getItem('access_token')
                },
                "data": {"geopath_id": value}
            }
            $.ajax(getSettings).done(function (response) {
                // console.log(response)
                let frameObj = response['data']
                let frameid = frameObj['id']
                frameObj['classification_type']['id'] = newclassid
                clearRedundantFields(frameObj)
                let frameString = JSON.stringify(frameObj);
                let putSettings = {
                    "async": false,
                    "url": process.env.appurl + "/api/updateFrame/",
                    "method": "post",
                    "content-type": "application/json",
                    "headers": {
                        "Authorization": "Bearer " + localStorage.getItem('access_token'),
                        "Geopath-User-Service-Account": localStorage.getItem('userEmail')
                    },
                    "data": {"frameObj": frameString}
                };
                $.ajax(putSettings).done(function (response) {
                    if (response === 'OK') {
                        successUpdates.push(frameid)
                        resolve()
                    } else {
                        failedUpdate.push(frameid)
                        resolve()
                    }
                })
            })
        }))

    })
    await Promise.all(updatePromises).then(function () {
        // console.log('Promises Done')
        $("#classChangeSpinner").attr("hidden", true)
        $("#classChangeProgressMsg").html("<div id='classChangeSuccess'> Total Frames Successfully Updated: " + successUpdates.length + "</div><br><div id='classChangeFail'>Total Frames Failed: " + failedUpdate.length + "</div>")
        // $.each(successUpdates, function (index,value){
        //
        // })
    })
}

export async function changeStatus(frames, idvalue) {
    $("#statusloadingspinner").attr("hidden", false)
    let newstatusid = idvalue[0]['id']
    let updatePromises = []
    let successUpdates = []
    let failedUpdate = []
    $.each(frames, function (index, value) {
        updatePromises.push(new Promise(resolve => {
            let getSettings = {
                "async": false,
                "url": process.env.appurl + '/api/getFrameInfo',
                "method": "GET",
                "headers": {
                    "Content-Type": "application/x-www-form-urlencoded/",
                    "Authorization": "Bearer " + localStorage.getItem('access_token')
                },
                "data": {"geopath_id": value}
            }

            $.ajax(getSettings).done(function (response) {
                // console.log(response)
                let frameObj = response['data']
                let frameid = frameObj['id']
                frameObj['status_type']['id'] = newstatusid
                clearRedundantFields(frameObj)
                let frameString = JSON.stringify(frameObj);
                let putSettings = {
                    "async": false,
                    "url": process.env.appurl + "/api/updateFrame/",
                    "method": "post",
                    "content-type": "application/json",
                    "headers": {
                        "Authorization": "Bearer " + localStorage.getItem('access_token'),
                        "Geopath-User-Service-Account": localStorage.getItem('userEmail')
                    },
                    "data": {"frameObj": frameString}
                };
                $.ajax(putSettings).done(function (response) {
                    if (response === 'OK') {
                        successUpdates.push(frameid)
                        resolve()
                    } else {
                        failedUpdate.push(frameid)
                        resolve()
                    }

                })
            })

        }))
    })


    await Promise.all(updatePromises).then(function () {
        // console.log('Promises Done')
        $("#statusloadingspinner").attr("hidden", true)
        $("#statusToolMsg").html("<div id='statusChangeSuccess'> Total Frames Successfully Updated: " + successUpdates.length + "</div><br><div id='statusChangeFail'>Total Frames Failed: " + failedUpdate.length + "</div>")
        // $.each(successUpdates, function (index,value){
        //
        // })
    })
}

export async function createAccount() {
    $("#createAccountToolloadingspinner").attr("hidden", false)
    let accountname = $("#inputCreateAccountName").val()
    let accountcode = $("#inputCreateAccountCode").val()
    let parentaccountname
    let parentaccountid
    let reptype = $("#createAccountToolRepTypeDropDown").select2('data')[0]['id']
    let notes = $("#inputCreateAccountNotes").val()

    if ($("#createAccountToolParentDropDown").select2('data')[0]['id']) {
        parentaccountid = $("#createAccountToolParentDropDown").select2('data')[0]['id']
        parentaccountname = $("#createAccountToolParentDropDown").select2('data')[0]['text']
    } else {
        parentaccountid = null
        parentaccountname = $("#inputCreateAccountName").val()
    }
    let settings = {
        "async": false,
        "url": process.env.appurl + '/api/createAccount',
        "method": "get",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "name": accountname,
            "operator_code": accountcode,
            "parent_account_id": parentaccountid,
            "parent_account_name": parentaccountname,
            "updated_desc": notes,
            "current_ind": true,
            "created_by": localStorage.getItem('userEmail'),
        }
    }
    $.ajax(settings).done(function (response) {
        // console.log(response)
        let accountid = response['data']['id']
        let putSettings = {
            "async": false,
            "url": process.env.appurl + "/api/createRep/",
            "method": "get",
            "content-type": "application/json",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Geopath-User-Service-Account": localStorage.getItem('userEmail')
            },
            "data": {
                "account_id": accountid,
                "rep_type": reptype,
                "created_by": localStorage.getItem('userEmail')
            }
        };
        if (reptype > 0) {
            $.ajax(putSettings).done(function (response) {
                if (response['status'] === 200) {
                    // successUpdates.push(accountid)
                    $("#createAccountToolloadingspinner").attr("hidden", true)
                    $("#createAccountToolMsg").text("Account Created: " + accountid)
                } else {
                    $("#createAccountToolloadingspinner").attr("hidden", true)
                    $("#createAccountToolMsg").text("ERROR - REVIEW AND TRY AGAIN")
                }
            })
        } else {
            $("#createAccountToolloadingspinner").attr("hidden", true)
            $("#createAccountToolMsg").text("Account Created: " + accountid)
        }
    })
}

export async function createRepresentation() {
    $("#createRepToolloadingspinner").attr("hidden", false)
    let account_id = $("#createRepAccountSelector").select2('data')[0]['id']
    let rep_type = $("#createRepToolRepTypeDropDown").select2('data')[0]['id']

    let settings = {
        "async": false,
        "url": process.env.appurl + '/api/createRep',
        "method": "get",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "account_id": account_id,
            "rep_type": rep_type,
            "created_by": localStorage.getItem('userEmail'),
        }
    }
    $.ajax(settings).done(function (response) {
        if (response['status'] === 200) {
            // successUpdates.push(accountid)
            $("#createRepToolloadingspinner").attr("hidden", true)
            $("#createRepToolMsg").text("Represenation Created")
        } else {
            $("#createRepToolloadingspinner").attr("hidden", true)
            $("#createRepToolMsg").text("ERROR - REVIEW AND TRY AGAIN")
        }
    })

}

export async function loadPlaceAssignmentCSV(evt) {
    $("#placeAssignCount").html('')
    $("#placeAssignProgressMsg").html('')
    $("#placeAssignBtn").attr('hidden', true)
    let file = evt.target.files[0];
    let assignmentList = []
    let assignmentListPromises = []
    Papa.parse(file, {
        dynamicTyping: true,
        complete: async function (results) {
            // console.log(results)
            $.each(results.data, function (index, value) {
                assignmentListPromises.push(new Promise(function (resolve) {
                    // console.log(value)
                    if (value[0]) {
                        assignmentList.push({frame_id:value[0],place_id:value[1]})
                    }
                    resolve()
                }))
            })
            await Promise.all(assignmentListPromises).then(function () {
                // console.log(assignmentList)
                $("#placeAssignCount").html('<span>Total Assignments to Make: ' + assignmentList.length + '</span>')
                $("#placeAssignBtn").attr('hidden', false)
                $("#placeAssignBtn").on("click", function () {
                    makePlaceBasedAssignments(assignmentList)
                })
            })
        }
    })
}

