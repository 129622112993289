import $ from "jquery";
import {gp_inv_vt_min} from "../layers/vectorTileLayers";
import {setNewMapCenter, transform4326to3857} from "../supportFunctions/mapFunctions";
import {map} from "../map";
import {pointHandler} from "../points/pointHandler";
import {coordinateDisplay} from "../map_events/coordinateFunctions";
import {
    clearAssignmentHeaderText,
    clearInfoDiv,
    clearMapUtilsBody,
    clearMapUtilsHeader,
    clearNextButton,
    clearOtherInfoDiv,
    clearPrevButton,
    clearSubMapRow,
    hideAssignManagementButtons,
    hideAssignmentsButton,
    hideAssignmentTable,
    hideInfoLoading,
    info,
    searchfield,
    showInfoLoading
} from "../supportFunctions/consts";
import {clearAssignmentLayer, clearCoordinateSearchLayer} from "../layers/activeLayers";
import {streetDefaultStyle} from "../map_styles/lineStyle";

export function searchSpotId(spot_id){
    return {
        "url": process.env.appurl + "/api/searchInventoryDB/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "spot_id": spot_id,
            "noncurrent" : $("#lookupToolCurrentInput").is(":checked")
        }
    }
}
export function searchFrameId(frame_id){
    return {
        "url": process.env.appurl + "/api/searchInventoryDBFrame/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "frame_id": frame_id,
            "noncurrent" : $("#lookupToolCurrentInput").is(":checked")
        }
    }
}

export function inventorySearch (searchedId){
    showInfoLoading()
    let settings = searchSpotId(searchedId);
    //send the returned object to the geoserver for the lookup response
    $.ajax(settings).done( function (response) {
        clearInfoDiv()
        clearOtherInfoDiv()
        clearNextButton()
        clearPrevButton()
        clearAssignmentLayer()
        clearCoordinateSearchLayer()
        clearMapUtilsBody()
        clearMapUtilsHeader()
        streetDefaultStyle()
        clearSubMapRow()
        hideAssignmentsButton()
        hideAssignmentTable()
        clearAssignmentHeaderText()
        hideAssignManagementButtons()
        //set frame id regardless of the type of id that was searched
        if (response.length === 0){
            alert('Spot ID not found, please ensure the spot ID you are searching for exists')
            hideInfoLoading()
        }
        let frame_id = parseInt(response[0]['frame_id'])
        // console.log(frame_id)
        //zoom to location
        setNewMapCenter(transform4326to3857(response[0]['longitude'],response[0]['latitude']))
        //display new coordinates
        // console.log('new coordinate display')
        coordinateDisplay([response[0]['longitude'],response[0]['latitude']])
        //wait till map is finished rendering this one time to proceed
        map.once('rendercomplete', function () {
            // console.log('rendercomplete')
            //get extent of the current view
            let extent = map.getView().calculateExtent(map.getSize())
            let matched = false
            // console.log(gp_inv_vt_min.getFeaturesInExtent(extent))
            //get all features from the frame source tiles in the current exent
            $.each(gp_inv_vt_min.getFeaturesInExtent(extent), function (index, value) {
                // console.log('one of the current values in extent ',value)
                //once matched to the searched frame id, handle like any other point
                if (value['values_']['frame_id'] === frame_id) {
                    if (matched === false){
                        // console.log("matched value " ,value)
                        matched = true
                        pointHandler([value])
                    }
                }
                else {
                    info.html(
                        '<div id="frame_info">' +
                        '<h5>Frame not Active, check map area for Decommissoned / Inactive Inventory</h5>' +
                        '</div>'
                    )
                    info.html(

                    )
                    hideInfoLoading();
                }
            })
        })
    })
    searchfield.val('')
}