import $ from 'jquery'
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css'
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import {Map, View} from "ol";
import LayerGroup from "ol/layer/Group";
import {
    cstation_vt_ly,
    gp_inv_vt_ly_decom,
    gp_inv_vt_ly_min,
    place_vt_ly,
    st2021_vt_ly,
    st2023_vt_ly,
    st_vt_ly
} from "./layers/vectorTileLayers";
import {bing_img_base, bing_st_base, esri_img_base, esri_st_base} from "./layers/baseLayers";
import {
    assignmentlayer, assignmentlayer2021, assignmentlayer2023,
    coordinateSearchLayer,
    filteredInventoryLayer,
    placebasedassignmentlayer,
    rotateLayer
} from "./layers/activeLayers";
import {transform} from 'ol/proj.js';
import LayerSwitcher from 'ol-layerswitcher';
import {clickControl} from "./map_events/clickControl";
import ol_interaction_Transform from "ol-ext/interaction/Transform";

//set view settings for the map
export const view = new View({
    center: transform([-95,40], 'EPSG:4326', 'EPSG:3857'),
    zoom: 4.0,
    maxZoom: 19,
    projection: 'EPSG:3857'
});

//create the main map object with the layer groups
export const map = new Map({
    target: 'map',
    layers: [
        new LayerGroup({
            title: 'Base maps',
            layers: [
                esri_img_base,
                esri_st_base,
                bing_st_base,
                bing_img_base,
                rotateLayer,
                coordinateSearchLayer,
                placebasedassignmentlayer
            ]
        }),
        new LayerGroup({
            title:"Networks",
            layers:[
                st_vt_ly,
                st2021_vt_ly,
                st2023_vt_ly
                // st2022_vt_ly
            ]
        }),
        new LayerGroup({
            title: 'Overlays',
            layers: [
                assignmentlayer,
                assignmentlayer2021,
                assignmentlayer2023,
                cstation_vt_ly,
                gp_inv_vt_ly_min,
                gp_inv_vt_ly_decom,
                filteredInventoryLayer,
                place_vt_ly
            ]
        }),

    ],
    view: view
})

//create the layer switcher widget and add it to the map
const layerSwitcher = new LayerSwitcher();
map.addControl(layerSwitcher);

//create click listener and go to click control
map.on('click', clickControl)

// Define your network layers in an array for easier management
const networkLayers = [st_vt_ly, st2021_vt_ly, st2023_vt_ly];

// Function to set all network layers to invisible, except the one passed as argument
function setOnlyThisLayerVisible(visibleLayer) {
    networkLayers.forEach(layer => {
        if (layer === visibleLayer) {
            layer.setVisible(true);
        } else {
            layer.setVisible(false);
        }
    });
}

// Listen to visibility change on each network layer
networkLayers.forEach(layer => {
    layer.on('change:visible', function() {
        if (layer.getVisible()) {
            setOnlyThisLayerVisible(layer);
        }
    });
});

function checkvisibility(){
    if(st_vt_ly.getVisible()){
        $("#assignmentNetworkYearText").html('');
        $("#assignmentNetworkYearText").html(
            '<btn class="h6">Current Network Year 2016</btn>'
        );
    }
    else if (st2021_vt_ly.getVisible()){
        $("#assignmentNetworkYearText").html('');
        $("#assignmentNetworkYearText").html(
            '<btn class="h6">Current Network Year 2021</btn>'
        );
    }
    else if (st2023_vt_ly.getVisible()){
        $("#assignmentNetworkYearText").html('');
        $("#assignmentNetworkYearText").html(
            '<btn class="h6">Current Network Year 2023</btn>'
        );
    }
}

st_vt_ly.on('change:visible', function (){
    checkvisibility()
})
st2021_vt_ly.on('change:visible', function (){
    checkvisibility()
})
st2023_vt_ly.on('change:visible', function (){
    checkvisibility()
})
checkvisibility()

export const transformInteraction = new ol_interaction_Transform({
    layers:rotateLayer,
    rotate:true,
    translate:true
});
map.addInteraction(transformInteraction);
transformInteraction.setActive(false);