import {map} from "../map";
import $ from "jquery";
import {gp_inv_vt_ly_decom, gp_inv_vt_ly_min} from "../layers/vectorTileLayers";
import {compare} from "../supportFunctions/mathFunctions";
import {displayFilteredInventoryTable} from "../infoDisplays/filteredInventory";
import {clearFilteredLayer, filteredInventoryLayer, filteredInventorySource} from "../layers/activeLayers";
import {filteredInventoryStyle} from "../map_styles/pointStyles";
import {GeoJSON} from 'ol/format';
import {
    clearFilteredInfo,
    enableFilterInventoryButton,
    filterAccountSelector,
    filterInventorySubmitButton,
    filterStatusSelector
} from "../supportFunctions/consts";

export let featurejson
export function getStatuses(account_id) {
    let settings = {
        "url": process.env.appurl + "/api/getStatuses",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {"account_id": account_id}

    }
    $.ajax(settings).done(function (response) {
        let select2formattedresponse = response.map(({status_type_id: id, status_type_name: text}) => ({
            id,
            text
        }))
        select2formattedresponse.sort(compare)
        filterStatusSelector.select2({
            placeholder: "Select Status",
            data: select2formattedresponse
        })
        filterStatusSelector.on('select2:select', function () {
            enableFilterInventoryButton()
        })
    })
}


filterAccountSelector.on('select2:select', function () {
    // filterStatusSelector.html('').select2({
    //     placeholder: "Select Status",
    //     data: [{
    //         id: '',
    //         text: ''
    //     }]
    // })
    let selectedAccount = filterAccountSelector.select2('data')
    // getStatuses(selectedAccount[0]['id'])
    // getInventoryList(selectedAccount[0]['id'])
    enableFilterInventoryButton()
})


export function getFilteredInventory() {
    clearFilteredLayer()
    let account_id = filterAccountSelector.select2('data')
    // let status_type_id = filterStatusSelector.select2('data')
    let data = {
        "account_id": account_id[0]['id']
        // "status_type_id": status_type_id[0]['id']
    }
    let settings = {
        "url": process.env.appurl + "/api/getFilteredInventory",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": data
    }
    $.ajax(settings).done(function (response) {
        displayFilteredInventory(response)
    })
}

export async function displayFilteredInventory(data) {
    let flat_table_props = []
    featurejson = []
    let layerPromises = [];
    let tablePromises = []
    $.each(data, function (index, value) {
        layerPromises.push(new Promise(function (resolve) {
            let newjson = new GeoJSON().readFeatures(value['feature'])
            featurejson.push(newjson)
            filteredInventorySource.addFeatures(newjson)
            resolve()
        }))
        tablePromises.push(new Promise(function (resolve) {
            flat_table_props.push(value['feature']['properties'])
            resolve()
        }))
    })
    await Promise.all(layerPromises).then(function () {
        gp_inv_vt_ly_min.setVisible(false)
        gp_inv_vt_ly_decom.setVisible(false)
        // filteredInventorySource.addFeatures(featurejson)
        filteredInventoryLayer.setStyle(filteredInventoryStyle)
        map.getView().fit(filteredInventorySource.getExtent())

    })
    await Promise.all(tablePromises).then(function () {
        displayFilteredInventoryTable(flat_table_props)
    })
}

filterInventorySubmitButton.on('click', function () {
    getFilteredInventory()
})

export function refreshFilter() {
    filteredInventorySource.clear()
    getFilteredInventory()
}

export function removeFilter() {
    clearFilteredLayer()
    gp_inv_vt_ly_min.setVisible(true)
    gp_inv_vt_ly_decom.setVisible(true)
    clearFilteredInfo()

}

export function updateMapExtentAfterSubfilter(rows) {
    // console.log(rows['length'])
    // console.log(filteredInventorySource.getFeatures()['length'])
    // if (rows['length'] !== filteredInventorySource.getFeatures()['length']){
    //     getExtentOfSubFilteredPoints()
    // }

}