import $ from 'jquery'
import {arrayAdd} from "../supportFunctions/mathFunctions";
import {assignmentlayer, assignmentlayer2021, assignmentlayer2023, assignmentsource, assignmentsource2021, assignmentsource2023} from "../layers/activeLayers";
import {Stroke, Style} from 'ol/style';
import {linestringstyleFunction, linestringstyleFunction2021, linestringstyleFunction2023} from "../map_styles/lineStyle.js";

export function trafficAgg(features) {
    const vehaadt = [];
    const pedsum = [];
    const vehcirc = [];
    $.each(features, function (index, value) {
        //aadt from dataset is actually circ, aadt backed into by dividing avg occ
        vehcirc.push(parseFloat(value['values_']['veh_aadt'])*value['values_']['wav_occ']);
        vehaadt.push(parseFloat(value['values_']['veh_aadt']));
        pedsum.push(parseFloat(value['values_']['ped_aadt']));
    })
    const vehtaadttot = vehaadt.reduce(arrayAdd);
    const pedtotal = pedsum.reduce(arrayAdd);
    const vehcirctot = vehcirc.reduce(arrayAdd);
    return [{"veh_tot_circ":vehcirctot,"veh_tot_aadt":vehtaadttot,"ped_tot":pedtotal}]
}

export function directionArray(features) {
    let directions = []
    $.each(features, function (index, value) {
        directions.push(value['values_']['direction'])
    })
    return directions
}

function returner (layer,source,network_year) {
    let featureprop = layer.getProperties();
    let featureprops = featureprop["source"]["featuresRtree_"]["items_"];
    $.each(featureprops, function (index,value) {
        let ol_uid = value["value"]["ol_uid"];
        $.each(source.getFeatures(),function (index,value){
            if(value['ol_uid'] === ol_uid){
                if (network_year === 2016){
                    value.setStyle(
                        linestringstyleFunction()
                    );
                }
                else if (network_year === 2021){
                    value.setStyle(
                        linestringstyleFunction2021()
                    );
                }
                else if (network_year === 2023){
                    value.setStyle(
                        linestringstyleFunction2023()
                    );
                }

            }
        })
    });
}

export function flashStreet (segment_id,network_year){
    let value_term
    let layer
    let source
    if (network_year === 2016){
        value_term = "segment_reg"
        layer = assignmentlayer
        source = assignmentsource
    }
    else if (network_year === 2021){
        value_term = "segment"
        layer = assignmentlayer2021
        source = assignmentsource2021
    }
    else if (network_year === 2023){
        value_term = "segment"
        layer = assignmentlayer2023
        source = assignmentsource2023
    }
    let featureprop = layer.getProperties();
    let featureprops = featureprop["source"]["featuresRtree_"]["items_"];
    $.each(featureprops, function (index,value) {
        if (value["value"]["values_"][value_term] === segment_id) {
            console.log('matched')
            let ol_uid = value["value"]["ol_uid"];
            $.each(source.getFeatures(),function (index,value){
                if(value['ol_uid'] === ol_uid){
                    console.log('setting style')
                    value.setStyle(
                        new Style({
                            stroke: new Stroke({
                                color: 'red',
                                width: 25
                            })
                        })
                    );
                }
            })
        }
    });
    setTimeout(function( ){
        returner(layer,source,network_year)
    },3000)
}

export async function getSegWKT(segment){
    let wkt = null
    let settings = {
        "url": process.env.appurl + "/api/getSegmentWKT/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "segment": segment
        }
    }
    $.ajax(settings).done(function (response) {
        wkt= response[0]['st_astext']
    })
    return wkt
}