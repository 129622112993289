import $ from 'jquery'
import {exisitingAssignments} from "./assignmentHandler";
import {mediaParse} from "../infoDisplays/tableParamBuilders.js";

export async function assignmentDupeChecker(newAssignmentObject) {
    let errorArray = [];
    let promiseArray = [];
    $.each(exisitingAssignments, function (index,value) {
        promiseArray.push(new Promise(function (resolve) {
                if(parseInt(value['frame_id']) === newAssignmentObject["frame_id"]){
                    // console.log(parseInt(value['frame_id']),newAssignmentObject["frame_id"]);
                    if (value['segment_id'] === newAssignmentObject['segment_id']){
                        // console.log(value['segment_id'],newAssignmentObject['segment_id']);
                        if (value["mode"] === newAssignmentObject['mode']){
                            // console.log(value["mode"],newAssignmentObject['mode']);
                            if(value['network_type'] === newAssignmentObject['network_type']){
                                errorArray.push('Error');
                                resolve()
                            }
                           resolve()
                        }
                        resolve()
                    }
                    resolve()
                }
                resolve()
            }
        ))
    });
    return await Promise.all(promiseArray).then(function () {
        return errorArray.length > 0;
    })
}

export async function assignmentOppDupeChecker (oppList, newAssignmentObject) {
    // console.log('checking for opposite dupes')
    let errorArray = [];
    let promiseArray = [];
    $.each(oppList, function (index,value) {
        promiseArray.push(new Promise(function (resolve) {
                if(parseInt(value['frame_id']) === newAssignmentObject["frame_id"]){
                    // console.log(parseInt(value['frame_id']),newAssignmentObject["frame_id"]);
                    if (value['segment_id'] === newAssignmentObject['segment_id']){
                        // console.log(value['segment_id'],newAssignmentObject['segment_id']);
                        if (value["mode"] === newAssignmentObject['mode']){
                            if(value['network_type'] === newAssignmentObject['network_type']){
                                errorArray.push('Error');
                                resolve()
                            }
                            resolve()
                        }
                        resolve()
                    }
                    resolve()
                }
                resolve()
            }
        ))
    });
    return await Promise.all(promiseArray).then(function () {
        // console.log(errorArray);
        return errorArray.length > 0;
    })
}

export async function assignmentNewDupeChecker (assignmentFormArray,newAssignmentObject){
    let errorArray = [];
    let promiseArray = [];
    if(assignmentFormArray.length > 0) {
        $.each(assignmentFormArray, function (index, value) {
            promiseArray.push(new Promise(function (resolve) {
                    if (parseInt(value['geopath_id']) === newAssignmentObject["frame_id"]) {
                        // console.log(parseInt(value['frame_id']), newAssignmentObject["frame_id"]);
                        if (value['segmentid'] === newAssignmentObject['segment_id']) {
                            // console.log(value['segment_id'], newAssignmentObject['segment_id']);
                            if (value["mode"] === newAssignmentObject['mode']) {
                                if(value['network_type'] === newAssignmentObject['network_type']){
                                    errorArray.push('Error');
                                    resolve()
                                }
                                resolve()
                            }
                            resolve()
                        }
                        resolve()
                    }
                    resolve()
                }
            ))
        });
        return await Promise.all(promiseArray).then(function () {
            // console.log(errorArray);
            return errorArray.length > 0;
        })
    }
    else return false
}